import React, { useState, useEffect } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
  PasswordInput,
  FileInput,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getReturnUrl, getBase64 } from '../../../utils';
import { Grid, GridItem } from '../../../components/Grid';

const AccountsCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [roles, setRoles] = useState([]);
  const [rolesId, setRolesId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [gender, setGender] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [address, setAddress] = useState('');
  const [photo, setPhoto] = useState([]);
  const [photoBase64, setPhotoBase64] = useState('');
  const [accountStatus, setAccountStatus] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const readRoles = () => {
    API.get(`/accounts-management/roles-and-permissions`).then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setRoles(data);
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      rolesId: rolesId,
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
      gender: gender,
      dateOfBirth: dateOfBirth,
      photo: photoBase64,
      address: address,
      accountStatus: accountStatus,
      password: password,
      confirmPassword: confirmPassword,
    };
    API.post('/accounts-management/accounts', formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setRolesId('');
    setFirstName('');
    setLastName('');
    setEmailAddress('');
    setGender('');
    setDateOfBirth('');
    setAddress('');
    setPhoto([]);
    setPhotoBase64('');
    setAccountStatus('');
    setPassword('');
    setConfirmPassword('');
  };

  useEffect(() => {
    readRoles();
  }, []);

  useEffect(() => {
    if (photo?.length > 0) {
      getBase64(photo[0], setPhotoBase64);
    }
  }, [photo]);

  const rolesOptions = [
    {
      title: '-- SELECT ROLE --',
      value: '',
    },
  ];
  roles &&
    roles.length > 0 &&
    roles.forEach(({ id, roleName }) =>
      rolesOptions.push({
        title: roleName,
        value: id,
      })
    );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form
          method='POST'
          action='#'
          onSubmit={onSubmit}
          enctype='multipart/form-data'
        >
          <Fieldset>
            <Label required>Role</Label>
            <Dropdown
              placeholder='Please select role'
              value={rolesId}
              onChange={setRolesId}
              options={rolesOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>First Name</Label>
                <TextInput
                  value={firstName}
                  onChange={setFirstName}
                  placeholder='Please enter first name'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Last Name</Label>
                <TextInput
                  value={lastName}
                  onChange={setLastName}
                  placeholder='Please enter last name'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label required>Email Address</Label>
            <TextInput
              value={emailAddress}
              onChange={setEmailAddress}
              placeholder='Please enter email address'
              disabled={isLoading}
            />
          </Fieldset>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label>Gender</Label>
                <Dropdown
                  placeholder='Please select gender'
                  value={gender}
                  onChange={setGender}
                  options={[
                    {
                      title: '-- SELECT GENDER --',
                      value: '',
                    },
                    {
                      title: 'Male',
                      value: 'Male',
                    },
                    {
                      title: 'Female',
                      value: 'Female',
                    },
                    {
                      title: 'Others',
                      value: 'Others',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Date Of Birth</Label>
                <TextInput
                  type='date'
                  value={dateOfBirth}
                  onChange={setDateOfBirth}
                  placeholder='Please select date of birth'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Address</Label>
            <TextInput
              value={address}
              onChange={setAddress}
              placeholder='Please enter address'
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Photo</Label>
            <FileInput
              files={photo}
              onChange={setPhoto}
              accept='image/*'
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Account Status</Label>
            <Dropdown
              placeholder='Please select account status'
              value={accountStatus}
              onChange={setAccountStatus}
              options={[
                {
                  title: '-- SELECT ACCOUNT STATUS --',
                  value: '',
                },
                {
                  title: 'Active',
                  value: 'Active',
                },
                {
                  title: 'Inactive',
                  value: 'Inactive',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Password</Label>
                <PasswordInput
                  placeholder='Please enter password'
                  value={password}
                  onChange={setPassword}
                  autoComplete='off'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Confirm Password</Label>
                <PasswordInput
                  placeholder='Please enter confirm password'
                  value={confirmPassword}
                  onChange={setConfirmPassword}
                  autoComplete='off'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type='submit'>
              Create
            </CreateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(AccountsCreate);
