import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  NumberInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
  FileInput,
} from '../../../components/FormElements';
import Modal from '../../../components/Modal';
import { Image } from '../../../components/Image';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getBase64, getReturnUrl } from '../../../utils';
import { Grid, GridItem } from '../../../components/Grid';

const StyledViewAttachmentButton = styled.span`
  font-size: 14px;
  width: calc(100% - 22px);
  height: auto;
  border: 1px solid #d0eeff;
  display: block;
  margin: 20px 0px 0px;
  background-color: #edf8ff;
  padding: 15px 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s;
  position: relative;
  &:hover {
    background-color: #d0eeff;
  }
`;

const UsersUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [showExistingImage, setShowExistingImage] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [mobileVerificationStatus, setMobileVerificationStatus] = useState('');
  const [emailVerificationStatus, setEmailVerificationStatus] = useState('');
  const [gender, setGender] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [photo, setPhoto] = useState('');
  const [photoBase64, setPhotoBase64] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/accounts-management/users/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            firstName,
            lastName,
            mobileNumber,
            emailAddress,
            mobileVerificationStatus,
            emailVerificationStatus,
            gender,
            dateOfBirth,
            photo,
            userStatus,
          } = data[0];
          setFirstName(firstName);
          setLastName(lastName);
          setMobileNumber(mobileNumber);
          setEmailAddress(emailAddress);
          setMobileVerificationStatus(mobileVerificationStatus);
          setEmailVerificationStatus(emailVerificationStatus);
          setGender(gender);
          setDateOfBirth(dateOfBirth);
          setPhoto([]);
          setPhotoBase64('');
          setUserStatus(userStatus);
          setOriginalFormData({
            firstName,
            lastName,
            mobileNumber,
            emailAddress,
            mobileVerificationStatus,
            emailVerificationStatus,
            gender,
            dateOfBirth,
            photo,
            userStatus,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      firstName,
      lastName,
      emailAddress,
      mobileVerificationStatus,
      emailVerificationStatus,
      gender,
      dateOfBirth,
      photo: photoBase64,
      userStatus,
    };
    API.put(`/accounts-management/users/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      firstName,
      lastName,
      mobileNumber,
      emailAddress,
      mobileVerificationStatus,
      emailVerificationStatus,
      gender,
      dateOfBirth,
      userStatus,
    } = originalFormData;
    setFirstName(firstName);
    setLastName(lastName);
    setMobileNumber(mobileNumber);
    setEmailAddress(emailAddress);
    setMobileVerificationStatus(mobileVerificationStatus);
    setEmailVerificationStatus(emailVerificationStatus);
    setGender(gender);
    setDateOfBirth(dateOfBirth);
    setPhoto([]);
    setPhotoBase64('');
    setUserStatus(userStatus);
  };

  useEffect(() => {
    readData();
  }, []);

  useEffect(() => {
    if (photo?.length > 0) {
      getBase64(photo[0], setPhotoBase64);
    }
  }, [photo]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form
          method='POST'
          action='#'
          onSubmit={onSubmit}
          enctype='multipart/form-data'
        >
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>First Name</Label>
                <TextInput
                  value={firstName}
                  onChange={setFirstName}
                  placeholder='Please enter first name'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Last Name</Label>
                <TextInput
                  value={lastName}
                  onChange={setLastName}
                  placeholder='Please enter last name'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Mobile Number</Label>
                <NumberInput
                  placeholder='Please enter mobile number'
                  value={mobileNumber}
                  onChange={setMobileNumber}
                  maxLength={10}
                  autoComplete='off'
                  disabled={true}
                  pattern={`[0-9]*`}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Mobile Verification Status</Label>
                <Dropdown
                  placeholder='Please select mobile verification status'
                  value={mobileVerificationStatus}
                  onChange={setMobileVerificationStatus}
                  options={[
                    {
                      title: '-- SELECT MOBILE VERIFICATION STATUS --',
                      value: '',
                    },
                    {
                      title: 'Verified',
                      value: 'Verified',
                    },
                    {
                      title: 'Not Verified',
                      value: 'Not Verified',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label>Email Address</Label>
                <TextInput
                  value={emailAddress}
                  onChange={setEmailAddress}
                  placeholder='Please enter email address'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Email Verification Status</Label>
                <Dropdown
                  placeholder='Please select email verification status'
                  value={emailVerificationStatus}
                  onChange={setEmailVerificationStatus}
                  options={[
                    {
                      title: '-- SELECT EMAIL VERIFICATION STATUS --',
                      value: '',
                    },
                    {
                      title: 'Verified',
                      value: 'Verified',
                    },
                    {
                      title: 'Not Verified',
                      value: 'Not Verified',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label>Gender</Label>
                <Dropdown
                  placeholder='Please select gender'
                  value={gender}
                  onChange={setGender}
                  options={[
                    {
                      title: '-- SELECT GENDER --',
                      value: '',
                    },
                    {
                      title: 'Male',
                      value: 'Male',
                    },
                    {
                      title: 'Female',
                      value: 'Female',
                    },
                    {
                      title: 'Others',
                      value: 'Others',
                    },
                  ]}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Date Of Birth</Label>
                <TextInput
                  type='date'
                  value={dateOfBirth}
                  onChange={setDateOfBirth}
                  placeholder='Please select date of birth'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Photo</Label>
            <FileInput
              files={photo}
              onChange={setPhoto}
              accept='image/*'
              disabled={isLoading}
            />
            {originalFormData?.photo && (
              <StyledViewAttachmentButton
                onClick={() => {
                  setShowExistingImage(true);
                  setModalImage(originalFormData.photo);
                }}
              >
                Click here to view existing file
              </StyledViewAttachmentButton>
            )}
          </Fieldset>
          <Fieldset>
            <Label required>User Status</Label>
            <Dropdown
              placeholder='Please select user status'
              value={userStatus}
              onChange={setUserStatus}
              options={[
                {
                  title: '-- SELECT USER STATUS --',
                  value: '',
                },
                {
                  title: 'Active',
                  value: 'Active',
                },
                {
                  title: 'Inactive',
                  value: 'Inactive',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type='submit'>
              Update
            </UpdateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
        <Modal
          modalStatus={showExistingImage}
          setModalStatus={setShowExistingImage}
          hideCloseButton
        >
          <Image width={150} source={modalImage} alt={'Existing File'} />
        </Modal>
      </FormContainer>
    </>
  );
};

export default withAppHOC(UsersUpdate);
