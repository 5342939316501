import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../../components/NoData/NoData";
import StatusHighliter from "../../../components/StatusHighliter/StatusHighliter";
import {
  MessageBox,
  Form,
  Fieldset,
  Button,
  ResetButton,
  Dropdown,
  CustomButton,
  Label,
} from "../../../components/FormElements";
import { TableBuilder } from "../../../components/TableElements";
import Pagination from "../../../components/Pagination/Pagination";
import FiltersButton from "../../../components/FiltersButton/FiltersButton";
import { StyledFiltersContainer, StyledSpan } from "../../../components/Styled";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from "../../../utils";

const RatingsAndReviewsList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 10,
  });
  const [products, setProducts] = useState([]);
  const [productsId, setProductsId] = useState([]);
  const [ratingsAndReviews, setRatingsAndReviews] = useState("");

  const readProducts = () => {
    API.get(`/content-management/products`).then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setProducts(data);
      }
    });
  };

  const readData = useCallback(
    (productsId = "") => {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      setRatingsAndReviews([]);
      API.get(
        `/content-management/ratings-and-reviews/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&productsId=${productsId}`
      )
        .then((response) => {
          const { status, message, data, totalRecords } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILURE) {
              setResponseStatus(status);
              setResponseMessage(message);
            } else {
              setTotalRecords(totalRecords);
            }
            setRatingsAndReviews(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const updateRatingsAndReviewStatus = (id, approvalStatus) => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.put(`/content-management/ratings-and-reviews/${id}`, { approvalStatus })
      .then((response) => {
        const { status, message } = response.data;
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          reloadData();
        } else {
          setResponseStatus(status);
          setResponseMessage(message);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const reloadData = () => {
    readData(productsId);
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  const resetForm = () => {
    setProductsId("");
    readData();
  };

  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(productsId);
    }
  }, [paginationData, readData]);

  useEffect(() => {
    readProducts();
  }, []);

  const { accountsPermissions } = props;
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;

  const productsOptions = [
    {
      title: "-- SELECT PRODUCTS --",
      value: "",
    },
  ];
  products &&
    products.length > 0 &&
    products.forEach(({ id, productName }) =>
      productsOptions.push({
        title: productName,
        value: id,
      })
    );

  return (
    <>
      <PageHeading
        {...props}
        showCreate={true}
        canReload={true}
        reloadData={reloadData}
      />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? "show" : ""}>
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>Product</Label>
            <Dropdown
              placeholder="Product"
              value={productsId}
              onChange={setProductsId}
              options={productsOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type="submit">
              Filter
            </Button>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={
          responseStatus === "" && ratingsAndReviews.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          ratingsAndReviews.length === 0
        }
        message={`No ratings & reviews found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {ratingsAndReviews.length > 0 && (
        <>
          <TableBuilder
            isLoading={ratingsAndReviews.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: "",
                dataSelector: "id",
                sticky: true,
                canSort: false,
                width: "50px",
                CellRenderer: (value, item) =>
                  item.approvalStatus === "Pending" &&
                  canUpdate && (
                    <>
                      <CustomButton
                        icon={faCircleCheck}
                        onClick={() => {
                          updateRatingsAndReviewStatus(value, "Approved");
                        }}
                      />
                      <CustomButton
                        icon={faCircleXmark}
                        onClick={() => {
                          updateRatingsAndReviewStatus(value, "Rejected");
                        }}
                        style={{ color: "red" }}
                      />
                    </>
                  ),
              },
              {
                title: "Customer",
                dataSelector: "firstName",
                dataType: "string",
                CellRenderer: (value, item) => (
                  <>
                    {value} {item.lastName}
                    <StyledSpan>{item.mobileNumber}</StyledSpan>
                  </>
                ),
              },
              {
                title: "Product",
                dataSelector: "productName",
                dataType: "string",
                CellRenderer: (value) => (value ? value : "N/A"),
              },
              {
                title: "Order ID",
                dataSelector: "orderId",
                dataType: "string",
                CellRenderer: (value) => (value ? value : "N/A"),
              },
              {
                title: "Rating",
                dataSelector: "rating",
                dataType: "string",
                CellRenderer: (value) => parseFloat(value),
              },
              {
                title: "Review",
                dataSelector: "review",
                dataType: "string",
                CellRenderer: (value) => (value ? value : "N/A"),
              },
              {
                title: "Approval Status",
                dataSelector: "approvalStatus",
                dataType: "string",
                align: "center",
                CellRenderer: (value) =>
                  value && (
                    <StatusHighliter
                      className={
                        value === "Approved"
                          ? "green"
                          : value === "Rejected"
                          ? "red"
                          : "yellow"
                      }
                    >
                      {value}
                    </StatusHighliter>
                  ),
              },
              {
                title: "Created On",
                dataSelector: "createdOn",
                dataType: "date",
                CellRenderer: (value) =>
                  moment(value).format("YYYY-MM-DD hh:mm:ss A"),
              },
            ]}
            tableData={ratingsAndReviews}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(RatingsAndReviewsList);
