import React, { useState, useEffect, useCallback } from 'react';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../components/NoData/NoData';
import { MessageBox } from '../../components/FormElements';
import { TableBuilder } from '../../components/TableElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';

const ProductsInWishlist = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [productsInWishlist, setProductsInWishlist] = useState([]);

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    setProductsInWishlist([]);
    API.get(`/marketing/products-in-wishlist/`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        }
        setProductsInWishlist(data);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const reloadData = () => {
    readData();
  };

  useEffect(() => {
    readData();
  }, [readData]);

  return (
    <>
      <PageHeading {...props} canReload={true} reloadData={reloadData} />
      <LoadingSpinner
        isLoading={
          responseStatus === '' && productsInWishlist.length === 0 && isLoading
        }
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          productsInWishlist.length === 0
        }
        message={`No products in wishlist found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {productsInWishlist.length > 0 && (
        <TableBuilder
          isLoading={productsInWishlist.length !== 0 && isLoading}
          tableHeadings={[
            {
              title: 'Product ID',
              dataSelector: 'id',
              dataType: 'number',
            },
            {
              title: 'Product Details',
              dataSelector: 'productName',
              dataType: 'string',
            },
            {
              title: 'Number of Users',
              dataSelector: 'totalUsers',
              dataType: 'string',
            },
          ]}
          tableData={productsInWishlist}
        />
      )}
    </>
  );
};

export default withAppHOC(ProductsInWishlist);
