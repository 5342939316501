import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../../components/NoData/NoData';
import {
  CustomConfirmButton,
  Switch,
  MessageBox,
  Form,
  Fieldset,
  TextInput,
  Button,
  ResetButton,
  Label,
} from '../../../components/FormElements';
import { Image } from '../../../components/Image';
import { TableBuilder } from '../../../components/TableElements';
import Pagination from '../../../components/Pagination/Pagination';
import FiltersButton from '../../../components/FiltersButton/FiltersButton';
import {
  StyledLink,
  StyledFiltersContainer,
  StyledIconTitleContainer,
  StyledIconContainer,
  StyledTitleContainer,
  StyledSpan,
} from '../../../components/Styled';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from '../../../utils';

const AccountsList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isFiltering, setIsFiltering] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 10,
  });
  const [accounts, setAccounts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const readData = useCallback(
    (searchQuery = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      setAccounts([]);
      API.get(
        `/accounts-management/accounts/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${searchQuery}`
      )
        .then((response) => {
          const { status, message, data, pageInfo } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILURE) {
              setResponseStatus(status);
              setResponseMessage(message);
            } else {
              setTotalRecords(pageInfo?.totalRecords);
            }
            setAccounts(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const updateData = (value, data) => {
    const newData = { ...data };
    newData.photo = '';
    newData.accountStatus = value === true ? `Active` : `Inactive`;
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(`/accounts-management/accounts/${newData.id}`, newData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.delete(`/accounts-management/accounts/${id}`)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
        reloadData();
      });
  };

  const reloadData = () => {
    readData(searchQuery);
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  const resetForm = () => {
    setSearchQuery('');
    readData();
  };

  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(searchQuery);
    }
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;
  let updateLink = '';
  let updateTitle = '';
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }

  return (
    <>
      <PageHeading
        {...props}
        showCreate={true}
        canReload={true}
        reloadData={reloadData}
      />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Search</Label>
            <TextInput
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder='First Name, Last Name or Mobile Number'
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type='submit'>
              Filter
            </Button>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={responseStatus === '' && accounts.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          accounts.length === 0
        }
        message={`No accounts found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {accounts.length > 0 && (
        <>
          <TableBuilder
            isLoading={accounts.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: '',
                dataSelector: 'id',
                sticky: true,
                canSort: false,
                width: '50px',
                CellRenderer: (value) => (
                  <>
                    {canUpdate && (
                      <StyledLink
                        to={updateLink.replace(':id', value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                  </>
                ),
              },
              {
                title: 'Account',
                dataSelector: 'firstName',
                dataType: 'string',
                CellRenderer: (value, item) => (
                  <StyledIconTitleContainer>
                    <StyledIconContainer>
                      <Image
                        source={item.photo ? `${item.photo}` : '/no-image.png'}
                        alt={value}
                        style={{ width: '50px', height: '50px' }}
                      />
                    </StyledIconContainer>
                    <StyledTitleContainer>
                      {value} {item.lastName}
                      <StyledSpan>{item.roleName}</StyledSpan>
                    </StyledTitleContainer>
                  </StyledIconTitleContainer>
                ),
              },
              {
                title: 'Created By',
                dataSelector: 'createdByFirstName',
                dataType: 'string',
                CellRenderer: (value, item) =>
                  value ? (
                    <>
                      {value} {item.createdByLastName}
                      {item.createdByRole && (
                        <StyledSpan>{item.createdByRole}</StyledSpan>
                      )}
                    </>
                  ) : (
                    'N/A'
                  ),
              },
              {
                title: 'Created On',
                dataSelector: 'createdOn',
                dataType: 'date',
                CellRenderer: (value) =>
                  moment(value).format('YYYY-MM-DD hh:mm:ss A'),
              },
              {
                title: 'Status',
                dataSelector: 'accountStatus',
                dataType: 'string',
                align: 'center',
                canSort: false,
                CellRenderer: (value, item) => {
                  return (
                    <Switch
                      value={value === 'Active'}
                      onChange={(value) => updateData(value, item)}
                    />
                  );
                },
              },
            ]}
            tableData={accounts}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(AccountsList);
