import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { PERMISSION_TYPES, ROUTE_TYPES } from '../constants';
import { ROUTES } from '../constants/routes.config';

const getPlatform = () => {
  return 'Web';
};

const getDeviceType = () => {
  return navigator?.userAgentData?.mobile ? 'Mobile' : 'Desktop';
};

const getOperatingSystem = () => {
  return navigator?.userAgentData?.platform ?? 'Unknown';
};

const shouldRedirect = (dataLength = 0, pageNumber = 1) => {
  return dataLength === 0 && parseInt(pageNumber) !== 1 ? true : false;
};

const redirectToPageOne = () => {
  window.location.href = window.location.href.split('?')?.[0];
};

const authData = {
  get: () => {
    return {
      isLoggedIn: window.localStorage.getItem('isLoggedIn') === 'true' || false,
      authId: window.localStorage.getItem('authId') || null,
      authToken: window.localStorage.getItem('authToken') || null,
      accountsPermissions:
        JSON.parse(window.localStorage.getItem('accountsPermissions')) || null,
      accountData:
        JSON.parse(window.localStorage.getItem('accountData')) || null,
    };
  },
  set: (key, value) => window.localStorage.setItem(key, value),
  clear: () => window.localStorage.clear(),
};

const checkRoutePermission = ({
  routeType,
  moduleName,
  permissionRequired,
}) => {
  const { ALLOW_IF_LOGGEDIN, ALLOW_IF_NOT_LOGGEDIN } = ROUTE_TYPES;
  const { isLoggedIn, accountsPermissions } = authData.get();
  const routeTypeCheck =
    isEmpty(routeType) || (routeType === ALLOW_IF_LOGGEDIN && isLoggedIn);
  const modulePermissionCheck =
    (routeType === ALLOW_IF_NOT_LOGGEDIN && isEmpty(moduleName)) ||
    (routeType === ALLOW_IF_LOGGEDIN &&
      !isEmpty(moduleName) &&
      !isEmpty(permissionRequired) &&
      (!isEmpty(accountsPermissions[moduleName])
        ? accountsPermissions[moduleName].includes(permissionRequired)
        : false));

  return routeTypeCheck && modulePermissionCheck;
};

const generateRoutes = () => {
  const routesList = [];

  (function loopRoutes(routes) {
    !isEmpty(routes) &&
      routes.forEach((route) => {
        route.isDummy !== true && route.path && routesList.push(route);
        if (!isEmpty(route.subRoutes)) {
          loopRoutes(route.subRoutes);
        }
      });
  })(ROUTES);

  return routesList;
};

const validators = {
  isNumber: (event) => {
    event = event ? event : window.event;
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  },
};

const getBase64 = async (files, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(files);
  reader.onload = () => {
    cb(reader.result);
  };
};

const camelCaseToString = (text) => {
  const result = text?.replace(/([A-Z])/g, ' $1');
  return result?.charAt(0)?.toUpperCase() + result?.slice(1) || '';
};

const getSearchParams = (key) => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(key);
};

const getReturnUrl = (type) => {
  let returnUrl = '/';
  const href = window.location.href;

  if (type === PERMISSION_TYPES.CREATE) {
    const exp = href.split('/create');
    returnUrl = exp[0];
  } else if (type === PERMISSION_TYPES.UPDATE) {
    const exp = href.split('/update');
    returnUrl = exp[0];
  }

  return returnUrl;
};

const formatCurrency = (value) => {
  const rupeeIndian = Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
  });

  return rupeeIndian.format(value);
};

const getPreviousDay = (date = new Date()) => {
  const previous = new Date(date.getTime());
  previous.setDate(date.getDate() - 1);

  return moment(previous).format('YYYY-MM-DD');
};

const getAmountInWords = (amount) => {
  const amountBreak = amount.split('.');
  const mainNumber = amountBreak[0];
  const decimalNumber = amountBreak[1];

  return decimalNumber
    ? `${convert(mainNumber)} Rupees and ${convert(decimalNumber)} Paise`
    : `${convert(mainNumber)} Rupees`;
};

function toNum(str) {
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '0') {
      str.replace('0', '');
    } else {
      break;
    }
  }
  return Number(str);
}

function convert(x) {
  let num = toNum(x);
  let str = '';
  let digits = num.toString().length;
  switch (digits) {
    case 1:
      str = single(num);
      break;
    case 2:
      str = twoDig(num);
      break;
    case 3:
      str = threeDig(num);
      break;
    case 4:
      str = fourDig(num);
      break;
    case 5:
      str = fiveDig(num);
      break;
    case 6:
      str = sixDig(num);
      break;
    case 7:
      str = sevenDig(num);
      break;
    case 8:
      str = eightDig(num);
      break;
    case 9:
      str = nineDig(num);
      break;
    case 10:
      str = tenDig(num);
      break;
    case 11:
      str = elevenDig(num);
      break;
    case 12:
      str = twelveDig(num);
      break;
    default:
      str = bigDig(x);
      break;
  }
  return str;
}

function single(num) {
  let str = '';
  switch (num) {
    case 0:
      str = 'zero';
      break;
    case 1:
      str = 'one';
      break;
    case 2:
      str = 'two';
      break;
    case 3:
      str = 'three';
      break;
    case 4:
      str = 'four';
      break;
    case 5:
      str = 'five';
      break;
    case 6:
      str = 'six';
      break;
    case 7:
      str = 'seven';
      break;
    case 8:
      str = 'eight';
      break;
    case 9:
      str = 'nine';
      break;
    default:
      str = '';
  }
  return str;
}

function twoDig(num) {
  let str = '';
  if (num.toString()[1] === '0') {
    switch (num) {
      case 10:
        str = 'ten';
        break;
      case 20:
        str = 'twenty';
        break;
      case 30:
        str = 'thirty';
        break;
      case 40:
        str = 'fourty';
        break;
      case 50:
        str = 'fifty';
        break;
      case 60:
        str = 'sixty';
        break;
      case 70:
        str = 'seventy';
        break;
      case 80:
        str = 'eighty';
        break;
      case 90:
        str = 'ninety';
        break;
      default:
        str = '';
    }
  } else if (num.toString()[0] === '1') {
    switch (num) {
      case 11:
        str = 'eleven';
        break;
      case 12:
        str = 'twelve';
        break;
      case 13:
        str = 'thirteen';
        break;
      case 14:
        str = 'fourteen';
        break;
      case 15:
        str = 'fifteen';
        break;
      case 16:
        str = 'sixteen';
        break;
      case 17:
        str = 'seventeen';
        break;
      case 18:
        str = 'eighteen';
        break;
      case 19:
        str = 'nineteen';
        break;
      default:
        str = '';
    }
  } else {
    str = twoDig(Number(num.toString()[0] + '0')) + ' ' + single(num % 10);
  }
  return str;
}

function threeDig(num) {
  let str = '';
  if (num % 100 === 0) {
    str = single(Number(num.toString()[0])) + ' hundred';
  } else if (num.toString()[1] === '0') {
    str =
      threeDig(Number(num.toString()[0]) + '00') + ' and ' + single(num % 10);
  } else {
    str =
      threeDig(Number(num.toString()[0]) + '00') + ' and ' + twoDig(num % 100);
  }
  return str;
}

function fourDig(num) {
  let str = '';
  if (num % 1000 === 0) {
    str = single(Number(num.toString()[0])) + ' thousand';
  } else {
    str =
      fourDig(Number(num.toString()[0]) + '000') +
      ', ' +
      convert(num.toString().slice(1));
  }
  return str;
}

function fiveDig(num) {
  let str = '';
  if (num % 1000 === 0) {
    str = twoDig(Number(num.toString()[0] + num.toString()[1])) + ' thousand';
  } else {
    str =
      fiveDig(Number(num.toString()[0] + num.toString()[1] + '000')) +
      ', ' +
      convert(num.toString().slice(2));
  }
  return str;
}

function sixDig(num) {
  let str = '';
  if (num % 100000 === 0) {
    str = single(Number(num.toString()[0])) + ' lakh';
  } else {
    str =
      sixDig(Number(num.toString()[0] + '00000')) +
      ', ' +
      convert(num.toString().slice(1));
  }

  return str;
}

function sevenDig(num) {
  let str = '';
  if (num % 1000000 === 0) {
    str = single(Number(num.toString()[0])) + ' million';
  } else {
    str =
      sevenDig(Number(num.toString()[0] + '000000')) +
      ', ' +
      convert(num.toString().slice(1));
  }

  return str;
}

function eightDig(num) {
  let str = '';
  if (num % 1000000 === 0) {
    str = twoDig(Number(num.toString()[0] + num.toString()[1])) + ' million';
  } else {
    str =
      eightDig(Number(num.toString()[0] + num.toString()[1]) + '000000') +
      ', ' +
      convert(num.toString().slice(2));
  }

  return str;
}

function nineDig(num) {
  let str = '';
  if (num % 1000000 === 0) {
    str =
      threeDig(
        Number(num.toString()[0] + num.toString()[1] + num.toString()[2])
      ) + ' million';
  } else {
    str =
      nineDig(
        Number(num.toString()[0] + num.toString()[1] + num.toString()[2]) +
          '000000'
      ) +
      ', ' +
      convert(num.toString().slice(3));
  }
  return str;
}

function tenDig(num) {
  let str = '';
  if (num % 1000000000 === 0) {
    str = single(Number(num.toString()[0])) + ' billion';
  } else {
    str =
      tenDig(Number(num.toString()[0] + '000000000')) +
      ', ' +
      convert(num.toString().slice(1));
  }
  return str;
}

function elevenDig(num) {
  let str = '';
  if (num % 1000000000 === 0) {
    str = twoDig(Number(num.toString()[0] + num.toString()[1])) + ' billion';
  } else {
    str =
      elevenDig(Number(num.toString()[0] + num.toString()[1] + '000000000')) +
      ', ' +
      convert(num.toString().slice(2));
  }
  return str;
}

function twelveDig(num) {
  let str = '';
  if (num % 1000000000 === 0) {
    str =
      threeDig(
        Number(num.toString()[0] + num.toString()[1] + num.toString()[2])
      ) + ' billion';
  } else {
    str =
      twelveDig(
        Number(
          num.toString()[0] +
            num.toString()[1] +
            num.toString()[2] +
            '000000000'
        )
      ) +
      ', ' +
      convert(num.toString().slice(3));
  }
  return str;
}

function bigDig(str) {
  let str1 = str.slice(0, -12);
  let str2 = str.slice(-12);
  return convert(str1) + ' trillion, ' + convert(str2);
}

export {
  getPlatform,
  getDeviceType,
  getOperatingSystem,
  shouldRedirect,
  redirectToPageOne,
  authData,
  checkRoutePermission,
  generateRoutes,
  validators,
  getBase64,
  camelCaseToString,
  getSearchParams,
  getReturnUrl,
  getAmountInWords,
  formatCurrency,
  getPreviousDay,
};
