import React, { useEffect, useState } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  FileInput,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getBase64, getReturnUrl } from '../../../utils';

const SubcategoriesCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [categories, setCategories] = useState([]);
  const [categoriesId, setCategoriesId] = useState('');
  const [subCategoryName, setSubCategoryName] = useState('');
  const [subCategoryImage, setSubCategoryImage] = useState([]);
  const [subCategoryImageBase64, setSubCategoryImageBase64] = useState('');
  const [subCategoryStatus, setSubCategoryStatus] = useState('');

  const readCategories = () => {
    API.get(`/content-management/categories`).then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setCategories(data);
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      categoriesId: categoriesId,
      subCategoryName: subCategoryName,
      subCategoryImage: subCategoryImageBase64,
      subCategoryStatus: subCategoryStatus,
    };
    API.post('/content-management/subcategories', formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setCategoriesId('');
    setSubCategoryName('');
    setSubCategoryImage(null);
    setSubCategoryStatus('');
  };

  useEffect(() => {
    readCategories();
  }, []);

  useEffect(() => {
    if (subCategoryImage?.length > 0) {
      getBase64(subCategoryImage[0], setSubCategoryImageBase64);
    }
  }, [subCategoryImage]);

  const categoriesOptions = [
    {
      title: '-- SELECT CATEGORY --',
      value: '',
    },
  ];
  categories &&
    categories.length > 0 &&
    categories.forEach(({ id, categoryName }) =>
      categoriesOptions.push({
        title: categoryName,
        value: id,
      })
    );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form
          method='POST'
          action='#'
          onSubmit={onSubmit}
          enctype='multipart/form-data'
        >
          <Fieldset>
            <Label required>Category</Label>
            <Dropdown
              placeholder='Please select category'
              value={categoriesId}
              onChange={setCategoriesId}
              options={categoriesOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Subcategory Name</Label>
            <TextInput
              value={subCategoryName}
              onChange={setSubCategoryName}
              placeholder='Please enter subcategory name'
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Subcategory Image</Label>
            <FileInput
              files={subCategoryImage}
              onChange={setSubCategoryImage}
              accept='image/*'
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Subcategory Status</Label>
            <Dropdown
              placeholder='Please select subcategory status'
              value={subCategoryStatus}
              onChange={setSubCategoryStatus}
              options={[
                {
                  title: '-- SELECT SUBCATEGORY STATUS --',
                  value: '',
                },
                {
                  title: 'Active',
                  value: 'Active',
                },
                {
                  title: 'Inactive',
                  value: 'Inactive',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type='submit'>
              Create
            </CreateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(SubcategoriesCreate);
