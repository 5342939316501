import React, { useState, useEffect, useCallback } from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { withAppHOC } from "../../../hoc";
import PageHeading from "../../../components/PageHeader/PageHeader";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NoData from "../../../components/NoData/NoData";
import StatusHighliter from "../../../components/StatusHighliter/StatusHighliter";
import {
  CustomConfirmButton,
  MessageBox,
  Form,
  Fieldset,
  TextInput,
  Button,
  ResetButton,
  Label,
} from "../../../components/FormElements";
import { TableBuilder } from "../../../components/TableElements";
import Pagination from "../../../components/Pagination/Pagination";
import FiltersButton from "../../../components/FiltersButton/FiltersButton";
import { StyledLink, StyledFiltersContainer } from "../../../components/Styled";
import API from "../../../api";
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from "../../../constants";
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from "../../../utils";

const CouponsList = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isFiltering, setIsFiltering] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams("pageNumber") || 1,
    recordsPerPage: getSearchParams("recordsPerPage") || 10,
  });
  const [coupons, setCoupons] = useState([]);
  const [couponCode, setCouponCode] = useState("");

  const readData = useCallback(
    (couponCode = "") => {
      setIsLoading(true);
      setResponseStatus("");
      setResponseMessage("");
      setCoupons([]);
      API.get(
        `/content-management/coupons/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&couponCode=${couponCode}`
      )
        .then((response) => {
          const { status, message, data, totalRecords } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILURE) {
              setResponseStatus(status);
              setResponseMessage(message);
            } else {
              setTotalRecords(totalRecords);
            }
            setCoupons(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const deleteData = (id) => {
    setIsLoading(true);
    setResponseStatus("");
    setResponseMessage("");
    API.delete(`/content-management/coupons/${id}`)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
        reloadData();
      });
  };

  const reloadData = () => {
    readData(couponCode);
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  const resetForm = () => {
    setCouponCode("");
    readData();
  };

  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(couponCode);
    }
  }, [paginationData, readData]);

  const { accountsPermissions, subRoutes } = props;
  let updateLink = "";
  let updateTitle = "";
  const canUpdate =
    accountsPermissions.includes(PERMISSION_TYPES.UPDATE) || false;
  const canDelete =
    accountsPermissions.includes(PERMISSION_TYPES.DELETE) || false;
  if (canUpdate) {
    const { title, path } = subRoutes.find(
      ({ permissionRequired }) => permissionRequired === PERMISSION_TYPES.UPDATE
    );
    updateLink = path;
    updateTitle = title;
  }

  return (
    <>
      <PageHeading
        {...props}
        showCreate={true}
        canReload={true}
        reloadData={reloadData}
      />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? "show" : ""}>
        <Form method="POST" action="#" onSubmit={filterData}>
          <Fieldset>
            <Label>Coupon Code</Label>
            <TextInput
              value={couponCode}
              onChange={setCouponCode}
              placeholder="Coupon Code"
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type="submit">
              Filter
            </Button>
            <ResetButton disabled={isLoading} type="button" onClick={resetForm}>
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={responseStatus === "" && coupons.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          coupons.length === 0
        }
        message={`No coupons found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {coupons.length > 0 && (
        <>
          <TableBuilder
            isLoading={coupons.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: "",
                dataSelector: "id",
                sticky: true,
                canSort: false,
                width: "50px",
                CellRenderer: (value) => (
                  <>
                    {canUpdate && (
                      <StyledLink
                        to={updateLink.replace(":id", value)}
                        title={updateTitle}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </StyledLink>
                    )}
                    {canDelete && (
                      <CustomConfirmButton onClick={() => deleteData(value)} />
                    )}
                  </>
                ),
              },
              {
                title: "Coupon Code",
                dataSelector: "couponCode",
                dataType: "string",
              },
              {
                title: "From Date",
                dataSelector: "fromDate",
                dataType: "date",
                CellRenderer: (value) =>
                  value && value !== "0000-00-00"
                    ? moment(value).format("YYYY-MM-DD")
                    : "N/A",
              },
              {
                title: "To Date",
                dataSelector: "toDate",
                dataType: "date",
                CellRenderer: (value) =>
                  value && value !== "0000-00-00"
                    ? moment(value).format("YYYY-MM-DD")
                    : "N/A",
              },
              {
                title: "Is Quantity Based Discount?",
                dataSelector: "isQuantityBasedDiscount",
                dataType: "string",
                align: "center",
                CellRenderer: (value) =>
                  value && (
                    <StatusHighliter
                      className={value === "Yes" ? "green" : "red"}
                    >
                      {value}
                    </StatusHighliter>
                  ),
              },
              {
                title: "Created On",
                dataSelector: "createdOn",
                dataType: "date",
                CellRenderer: (value) =>
                  moment(value).format("YYYY-MM-DD hh:mm:ss A"),
              },
            ]}
            tableData={coupons}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(CouponsList);
