import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  FileInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
} from '../../../components/FormElements';
import Modal from '../../../components/Modal';
import { Image } from '../../../components/Image';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getBase64, getReturnUrl } from '../../../utils';

const StyledViewAttachmentButton = styled.span`
  font-size: 14px;
  width: calc(100% - 22px);
  height: auto;
  border: 1px solid #d0eeff;
  display: block;
  margin: 20px 0px 0px;
  background-color: #edf8ff;
  padding: 15px 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s;
  position: relative;
  &:hover {
    background-color: #d0eeff;
  }
`;

const SubcategoriesUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [showExistingImage, setShowExistingImage] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [categories, setCategories] = useState([]);
  const [categoriesId, setCategoriesId] = useState('');
  const [subCategoryName, setSubCategoryName] = useState('');
  const [subCategoryImage, setSubCategoryImage] = useState('');
  const [subCategoryImageBase64, setSubCategoryImageBase64] = useState('');
  const [subCategoryStatus, setSubCategoryStatus] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const { categoriesId, subCategoryName, subCategoryStatus } =
      originalFormData;
    setCategoriesId(categoriesId);
    setSubCategoryName(subCategoryName);
    setSubCategoryStatus(subCategoryStatus);
  };

  const readCategories = () => {
    API.get(`/content-management/categories`).then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setCategories(data);
      }
    });
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/content-management/subcategories/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            categoriesId,
            subCategoryName,
            subCategoryImage,
            subCategoryStatus,
          } = data[0];
          setCategoriesId(categoriesId);
          setSubCategoryName(subCategoryName);
          setSubCategoryImage([]);
          setSubCategoryImageBase64('');
          setSubCategoryStatus(subCategoryStatus);
          setOriginalFormData({
            categoriesId,
            subCategoryName,
            subCategoryImage,
            subCategoryStatus,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      categoriesId,
      subCategoryName,
      subCategoryImage: subCategoryImageBase64
        ? subCategoryImageBase64
        : originalFormData.subCategoryImage,
      subCategoryStatus,
    };
    API.put(`/content-management/subcategories/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
    readCategories();
  }, [readData]);

  useEffect(() => {
    if (subCategoryImage?.length > 0) {
      getBase64(subCategoryImage[0], setSubCategoryImageBase64);
    }
  }, [subCategoryImage]);

  const categoriesOptions = [
    {
      title: '-- SELECT CATEGORY --',
      value: '',
    },
  ];
  categories &&
    categories.length > 0 &&
    categories.forEach(({ id, categoryName }) =>
      categoriesOptions.push({
        title: categoryName,
        value: id,
      })
    );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form
          method='POST'
          action='#'
          onSubmit={onSubmit}
          enctype='multipart/form-data'
        >
          <Fieldset>
            <Label required>Category</Label>
            <Dropdown
              placeholder='Please select category'
              value={categoriesId}
              onChange={setCategoriesId}
              options={categoriesOptions}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label required>Subcategory Name</Label>
            <TextInput
              value={subCategoryName}
              onChange={setSubCategoryName}
              placeholder='Please enter subcategory name'
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Subcategory Image</Label>
            <FileInput
              files={subCategoryImage}
              onChange={setSubCategoryImage}
              accept='image/*'
              disabled={isLoading}
            />
            {originalFormData?.subCategoryImage && (
              <StyledViewAttachmentButton
                onClick={() => {
                  setShowExistingImage(true);
                  setModalImage(originalFormData.subCategoryImage);
                }}
              >
                Click here to view existing file
              </StyledViewAttachmentButton>
            )}
          </Fieldset>
          <Fieldset>
            <Label required>Subcategory Status</Label>
            <Dropdown
              placeholder='Please select subcategory status'
              value={subCategoryStatus}
              onChange={setSubCategoryStatus}
              options={[
                {
                  title: '-- SELECT SUBCATEGORY STATUS --',
                  value: '',
                },
                {
                  title: 'Active',
                  value: 'Active',
                },
                {
                  title: 'Inactive',
                  value: 'Inactive',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type='submit'>
              Update
            </UpdateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
        <Modal
          modalStatus={showExistingImage}
          setModalStatus={setShowExistingImage}
          hideCloseButton
        >
          <Image width={150} source={modalImage} alt={'Existing File'} />
        </Modal>
      </FormContainer>
    </>
  );
};

export default withAppHOC(SubcategoriesUpdate);
