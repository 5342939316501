const MODULES = {
  DASHBOARD: 'DASHBOARD',
  // Accounts Management
  ACCOUNTS_MANAGEMENT: 'ACCOUNTS_MANAGEMENT',
  ROLES_AND_PERMISSIONS: 'ROLES_AND_PERMISSIONS',
  ACCOUNTS: 'ACCOUNTS',
  USERS: 'USERS',
  // Content Management
  CONTENT_MANAGEMENT: 'CONTENT_MANAGEMENT',
  BANNERS: 'BANNERS',
  CATEGORIES: 'CATEGORIES',
  SUBCATEGORIES: 'SUBCATEGORIES',
  PRODUCTS: 'PRODUCTS',
  COUPONS: 'COUPONS',
  RATINGS_AND_REVIEWS: 'RATINGS_AND_REVIEWS',
  // Order Management
  ORDER_MANAGEMENT: 'ORDER_MANAGEMENT',
  // Marketing
  MARKETING: 'MARKETING',
  PRODUCTS_IN_CART: 'PRODUCTS_IN_CART',
  PRODUCTS_IN_WISHLIST: 'PRODUCTS_IN_WISHLIST',
};

const PERMISSION_TYPES = {
  CREATE: 'CREATE',
  READ: 'READ',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  TRACK: 'TRACK',
  UPDATE_ORDER_TRACKING_INFO: 'UPDATE_ORDER_TRACKING_INFO',
  UPDATE_ORDER_STATUS: 'UPDATE_ORDER_STATUS',
};

const ROUTE_TYPES = {
  ALLOW_IF_LOGGEDIN: 'ALLOW_IF_LOGGEDIN',
  ALLOW_IF_NOT_LOGGEDIN: 'ALLOW_IF_NOT_LOGGEDIN',
};

const API_RESPONSE_TYPES = {
  SUCCESS: 'success',
  FAILURE: 'failure',
};

export { MODULES, PERMISSION_TYPES, ROUTE_TYPES, API_RESPONSE_TYPES };
