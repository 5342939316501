import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import Logo from '../Logo/Logo';
import SupportSection from '../SupportSection/SupportSection';
import AccountsSection from '../AccountsSection/AccountsSection';
import LogoutSection from '../LogoutSection/LogoutSection';

const StyledHeaderContainer = styled.header`
  width: 100%;
  height: auto;
  display: block;
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;

  @media (max-width: 600px) {
    border-bottom: 1px solid #e5e5e5;
  }
`;

const StyledHeader = styled.section`
  width: 100%;
  height: 55px;
  margin: 0px;
  padding: 0px;
  display: grid;
  grid-template-columns: max-content auto;
  @media (max-width: 600px) {
    grid-template-columns: min-content max-content auto;
  }
`;

const StyledAccountsSectionContainer = styled.section`
  width: auto;
  height: 55px;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: right;
`;

const StyledNavigationIcon = styled.section`
  display: none;
  @media (max-width: 600px) {
    width: 55px;
    height: 100%;
    margin: 0px;
    padding: 0px;
    border-right: 1px solid #e5e5e5;
    position: relative;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    color: #707070;
    transition: background-color 0.5s;
    font-size: 20px;
    &:hover {
      background-color: #e5e5e5;
    }
  }
`;

const Header = ({
  fullName,
  profilePhoto,
  onClickLogout,
  showNav,
  setShowNav,
  supportMobileNumber,
  supportEmailAddress,
}) => {
  return (
    <StyledHeaderContainer>
      <StyledHeader>
        <StyledNavigationIcon onClick={setShowNav}>
          <FontAwesomeIcon icon={showNav ? faXmark : faBars} />
        </StyledNavigationIcon>
        <Logo />
        <StyledAccountsSectionContainer>
          <SupportSection
            supportMobileNumber={supportMobileNumber}
            supportEmailAddress={supportEmailAddress}
          />
          <AccountsSection fullName={fullName} profilePhoto={profilePhoto} />
          <LogoutSection onClickLogout={onClickLogout} />
        </StyledAccountsSectionContainer>
      </StyledHeader>
    </StyledHeaderContainer>
  );
};

export default Header;
