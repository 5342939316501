import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Logo from '../../components/Logo/Logo';
import Footer from '../../components/Footer/Footer';
import {
  Form,
  Fieldset,
  Label,
  PasswordInput,
  OTPInput,
  Button,
  MessageBox,
  TextInput,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';

const StyledAuthContainer = styled.section`
  width: 100%;
  height: 100%;
  background-color: #3c3c3c;
  padding: 0px;
  margin: 0px;
  position: fixed;
  top: 0;
  left: 0;

  background: #88bb22;
`;

const StyledAuthArea = styled.section`
  width: 500px;
  height: auto;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0px;
  margin: 0px;
  box-shadow: 0px 0px 50px -10px #111b21;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  @media (max-width: 900px) {
    width: calc(100% - 40px);
    min-width: auto;
    height: auto;
    padding: 0px;
    margin: 0px;
    position: relative;
    top: 20px;
    left: 20px;
    transform: none;
  }
`;

const StyledHeader = styled.section`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 20px 0px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
`;

const StyledBody = styled.section`
  width: calc(100% - 60px);
  height: auto;
  margin: 0px;
  padding: 30px 30px 0px;
  @media (max-width: 900px) {
    max-width: 400px;
    margin: 0 auto;
  }
`;

const StyledAuthHeading = styled.h1`
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  margin: 0px 0px 30px 0px;
  padding: 0px;
`;

const StyleLink = styled(NavLink)`
  font-size: 14px;
  color: #0095ff;
  position: absolute;
  top: 11.5px;
  right: 0;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledSpanAsButton = styled.span`
  font-size: 14px;
  color: #0095ff;
  position: absolute;
  top: 15.5px;
  right: 0;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledFooter = styled.section`
  width: calc(100% - 60px);
  height: auto;
  text-align: center;
  margin: 0px;
  padding: 30px;
  footer {
    padding: 0px;
  }
`;

const StyledPara = styled.p`
  font-size: 14px;
  text-align: center;
  line-height: 30px;
`;

const StyledSpan = styled.span`
  color: forestgreen;
`;

const ForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [reponseMessage, setResponseMessage] = useState('');
  const [routeStage, setRouteStage] = useState('Reset Password');
  const [emailAddress, setEmailAddress] = useState('');
  const [requestToken, setRequestToken] = useState('');
  const [oneTimePassword, setOneTimePassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const resetForm = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
    setRouteStage('Reset Password');
    setRequestToken('');
    setOneTimePassword('');
    setPassword('');
    setConfirmPassword('');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    if (routeStage === 'Reset Password') {
      API.post('/auth/forgot-password', { emailAddress: emailAddress })
        .then((response) => {
          const { status, message, token } = response.data;
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setRequestToken(token);
            setRouteStage('OTP');
          } else {
            setResponseStatus(status);
            setResponseMessage(message);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (routeStage === 'OTP') {
      API.post('/auth/verify-otp', {
        requestType: 'Reset Password',
        requestToken: requestToken,
        emailAddress: emailAddress,
        oneTimePassword: oneTimePassword
          ? oneTimePassword.join('')
          : oneTimePassword,
      })
        .then((response) => {
          const { status, message, token } = response.data;
          setRequestToken(token);
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setRouteStage('RESET_PASSWORD');
          } else {
            setResponseStatus(status);
            setResponseMessage(message);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (routeStage === 'RESET_PASSWORD') {
      API.post('/auth/reset-password', {
        requestToken: requestToken,
        password: password,
        confirmPassword: confirmPassword,
      })
        .then((response) => {
          const { status, message } = response.data;
          setResponseStatus(status);
          setResponseMessage(message);
          if (status === API_RESPONSE_TYPES.SUCCESS) {
            setResponseMessage('Your password has been successfully changed');
            setRequestToken('');
            setEmailAddress('');
            setOneTimePassword('');
            setPassword('');
            setConfirmPassword('');
            setRouteStage('Reset Password');
            setTimeout(() => {
              resetForm();
              window.location.href = '/login';
            }, 5000);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onClickResendOTP = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.post('/auth/resend-otp', {
      requestType: 'Reset Password',
      emailAddress: emailAddress,
      requestToken: requestToken,
    })
      .then((response) => {
        const { status, message, token } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        setRequestToken(token);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <StyledAuthArea>
        <StyledHeader>
          <Logo type='auth' />
        </StyledHeader>
        <StyledBody>
          <StyledAuthHeading>
            {routeStage === 'Reset Password'
              ? `Forgot Password ?`
              : routeStage === 'OTP'
              ? `Verify & Reset Password`
              : `Change Password`}
          </StyledAuthHeading>
          <Form action='#' method='POST' onSubmit={onSubmit}>
            {routeStage === 'Reset Password' && (
              <Fieldset>
                <Label required>Email Address</Label>
                <TextInput
                  placeholder='Please enter your email address'
                  value={emailAddress}
                  onChange={setEmailAddress}
                  autoComplete='off'
                  disabled={isLoading}
                />
              </Fieldset>
            )}
            {routeStage === 'OTP' && (
              <>
                <StyledPara>
                  Please enter your OTP sent to{' '}
                  <StyledSpan>{emailAddress}</StyledSpan>
                </StyledPara>
                <Fieldset>
                  <OTPInput
                    values={oneTimePassword}
                    onChange={setOneTimePassword}
                    length={6}
                    alignInputs='center'
                    disabled={isLoading}
                  />
                </Fieldset>
              </>
            )}
            {routeStage === 'RESET_PASSWORD' && (
              <>
                <Fieldset>
                  <Label required>Password</Label>
                  <PasswordInput
                    placeholder='Please enter your password'
                    value={password}
                    onChange={setPassword}
                    autoComplete='off'
                    disabled={isLoading}
                  />
                </Fieldset>
                <Fieldset>
                  <Label required>Confirm Password</Label>
                  <PasswordInput
                    placeholder='Please re-enter your password'
                    value={confirmPassword}
                    onChange={setConfirmPassword}
                    autoComplete='off'
                    disabled={isLoading}
                  />
                </Fieldset>
              </>
            )}
            {responseStatus && (
              <Fieldset>
                <MessageBox status={responseStatus} message={reponseMessage} />
              </Fieldset>
            )}
            <Fieldset>
              <Button disabled={isLoading}>
                {routeStage === 'Reset Password'
                  ? `Send OTP`
                  : routeStage === 'OTP'
                  ? `Verify & Proceed`
                  : `Submit`}
              </Button>
              {routeStage === 'OTP' && (
                <StyledSpanAsButton onClick={onClickResendOTP}>
                  Resend OTP
                </StyledSpanAsButton>
              )}
              {routeStage === 'Reset Password' && (
                <StyleLink to='/login' title='Login'>
                  &larr; Back to Login
                </StyleLink>
              )}
            </Fieldset>
          </Form>
        </StyledBody>
        <StyledFooter>
          <Footer />
        </StyledFooter>
      </StyledAuthArea>
      <StyledAuthContainer />
    </>
  );
};

export default ForgotPassword;
