import React from 'react';
import styled from 'styled-components';
import { API_RESPONSE_TYPES } from '../../constants';

const StyledMessageBox = styled.section`
  display: block;
  width: calc(100% - 20px);
  height: auto;
  border: 0px;
  font-size: 14px;
  color: #ffffff;
  background-color: ${({ status }) =>
    status === API_RESPONSE_TYPES.SUCCESS ? '#39a639' : '#ff4d4d'};
  text-align: center;
  margin: 0px;
  padding: 15px 10px;
  border-radius: 5px;
`;

const MessageBox = ({ status, message, ...rest }) => {
  return (
    message &&
    message !== '' &&
    message !== null &&
    message !== undefined && (
      <StyledMessageBox status={status} {...rest}>
        {message}
      </StyledMessageBox>
    )
  );
};

export default MessageBox;
