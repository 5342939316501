import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
  NumberInput,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getReturnUrl } from '../../../utils';
import { Grid, GridItem } from '../../../components/Grid';
import { isNull } from 'lodash';

const CouponsUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [usersList, setUsersList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [couponCode, setCouponCode] = useState('');
  const [users, setUsers] = useState([]);
  const [products, setProducts] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [isQuantityBasedDiscount, setIsQuantityBasedDiscount] = useState('');
  const [minCartValue, setMinCartValue] = useState('');
  const [flatDiscount, setFlatDiscount] = useState('');
  const [discountType, setDiscountType] = useState('Flat');
  const [percentageDiscount, setPercentageDiscount] = useState('');
  const [minQuantity, setMinQuantity] = useState('');
  const [discountedQuantity, setDiscountedQuantity] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const readUsers = () => {
    API.get(`/accounts-management/users`).then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setUsersList(data);
      }
    });
  };

  const readProducts = () => {
    API.get(`/content-management/products`).then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setProductsList(data);
      }
    });
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/content-management/coupons/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const {
            couponCode,
            users,
            products,
            fromDate,
            toDate,
            isQuantityBasedDiscount,
            minCartValue,
            flatDiscount,
            percentageDiscount,
            minQuantity,
            discountedQuantity,
            discountType,
          } = data[0];
          setCouponCode(couponCode);
          setUsers(
            !isNull(users) ? users.split(',').map((item) => parseInt(item)) : []
          );
          setProducts(
            !isNull(products)
              ? products.split(',').map((item) => parseInt(item))
              : []
          );
          setFromDate(fromDate);
          setToDate(toDate);
          setIsQuantityBasedDiscount(isQuantityBasedDiscount);
          setMinCartValue(minCartValue);
          setFlatDiscount(flatDiscount);
          setPercentageDiscount(percentageDiscount);
          setMinQuantity(minQuantity);
          setDiscountedQuantity(discountedQuantity);
          setDiscountType(discountType);
          setOriginalFormData({
            couponCode,
            users: !isNull(users)
              ? users.split(',').map((item) => parseInt(item))
              : [],
            products: !isNull(products)
              ? products.split(',').map((item) => parseInt(item))
              : [],
            fromDate,
            toDate,
            isQuantityBasedDiscount,
            minCartValue,
            flatDiscount,
            percentageDiscount,
            minQuantity,
            discountedQuantity,
            discountType,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      couponCode: couponCode,
      users: users?.join(','),
      products: products?.join(','),
      fromDate: fromDate,
      toDate: toDate,
      isQuantityBasedDiscount: isQuantityBasedDiscount,
      minCartValue: minCartValue,
      flatDiscount: flatDiscount,
      percentageDiscount: percentageDiscount,
      minQuantity: minQuantity,
      discountedQuantity: discountedQuantity,
      discountType: discountType,
    };
    API.put(`/content-management/coupons/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const {
      couponCode,
      users,
      products,
      fromDate,
      toDate,
      isQuantityBasedDiscount,
      minCartValue,
      flatDiscount,
      percentageDiscount,
      minQuantity,
      discountedQuantity,
      discountType,
    } = originalFormData;
    setCouponCode(couponCode);
    setUsers(users);
    setProducts(products);
    setFromDate(fromDate);
    setToDate(toDate);
    setIsQuantityBasedDiscount(isQuantityBasedDiscount);
    setMinCartValue(minCartValue);
    setFlatDiscount(flatDiscount);
    setPercentageDiscount(percentageDiscount);
    setMinQuantity(minQuantity);
    setDiscountedQuantity(discountedQuantity);
    setDiscountType(discountType);
  };

  useEffect(() => {
    readUsers();
    readProducts();
    readData();
  }, [readData]);

  const usersOptions = [
    {
      title: '-- SELECT USERS --',
      value: '',
    },
  ];
  usersList &&
    usersList.length > 0 &&
    usersList.forEach(({ id, firstName, lastName, mobileNumber }) =>
      usersOptions.push({
        title: `${firstName} ${lastName}`,
        value: id,
        subTitle: mobileNumber,
      })
    );

  const productsOptions = [
    {
      title: '-- SELECT PRODUCTS --',
      value: '',
    },
  ];
  productsList &&
    productsList.length > 0 &&
    productsList.forEach(({ id, productName }) =>
      productsOptions.push({
        title: productName,
        value: id,
      })
    );

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form
          method='POST'
          action='#'
          onSubmit={onSubmit}
          enctype='multipart/form-data'
        >
          <Fieldset>
            <Label required>Coupon Code</Label>
            <TextInput
              value={couponCode}
              onChange={setCouponCode}
              placeholder='Please enter coupon code'
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label>Users</Label>
                <Dropdown
                  multiSelect={true}
                  searchable={true}
                  placeholder='Please select users'
                  value={users}
                  onChange={setUsers}
                  options={usersOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Products</Label>
                <Dropdown
                  multiSelect={true}
                  searchable={true}
                  placeholder='Please select products'
                  value={products}
                  onChange={setProducts}
                  options={productsOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label>From Date</Label>
                <TextInput
                  type='date'
                  value={fromDate}
                  onChange={setFromDate}
                  placeholder='From Date'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>To Date</Label>
                <TextInput
                  type='date'
                  value={toDate}
                  onChange={setToDate}
                  placeholder='To Date'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label required>Is Quantity Based Discount?</Label>
            <Dropdown
              placeholder='Please select coupon type'
              value={isQuantityBasedDiscount}
              onChange={setIsQuantityBasedDiscount}
              options={[
                {
                  title: '-- SELECT IF COUPON IS QUANTITY BASED DISCOUNT --',
                  value: '',
                },
                {
                  title: 'Yes',
                  value: 'Yes',
                },
                {
                  title: 'No',
                  value: 'No',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {isQuantityBasedDiscount === 'Yes' && (
            <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
              <GridItem>
                <Fieldset>
                  <Label required>Minimum Quantity</Label>
                  <NumberInput
                    value={minQuantity}
                    onChange={setMinQuantity}
                    placeholder='Please enter minimum quantity'
                    maxLength={100}
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>Discounted Quantity</Label>
                  <NumberInput
                    value={discountedQuantity}
                    onChange={setDiscountedQuantity}
                    placeholder='Please enter discounted quantity'
                    maxLength={100}
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
            </Grid>
          )}
          {isQuantityBasedDiscount === 'No' && (
            <Grid
              columns={
                'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
              }
            >
              <GridItem>
                <Fieldset>
                  <Label required>Discount Type</Label>
                  <Dropdown
                    placeholder='Please select discount type'
                    value={discountType}
                    onChange={setDiscountType}
                    options={[
                      {
                        title: 'Flat',
                        value: 'Flat',
                      },
                      {
                        title: 'Percentage',
                        value: 'Percentage',
                      },
                    ]}
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                <Fieldset>
                  <Label required>Minimum Cart Value</Label>
                  <NumberInput
                    value={minCartValue}
                    onChange={setMinCartValue}
                    placeholder='Please enter minimum cart value'
                    maxLength={100}
                    disabled={isLoading}
                  />
                </Fieldset>
              </GridItem>
              <GridItem>
                {discountType === 'Flat' ? (
                  <Fieldset>
                    <Label required>Flat Discount</Label>
                    <NumberInput
                      value={flatDiscount}
                      onChange={setFlatDiscount}
                      placeholder='Please enter flat discount'
                      maxLength={100}
                      disabled={isLoading}
                    />
                  </Fieldset>
                ) : (
                  <Fieldset>
                    <Label required>Percentage Discount</Label>
                    <NumberInput
                      value={percentageDiscount}
                      onChange={setPercentageDiscount}
                      placeholder='Please enter percentage discount'
                      maxLength={100}
                      disabled={isLoading}
                    />
                  </Fieldset>
                )}
              </GridItem>
            </Grid>
          )}
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type='submit'>
              Update
            </UpdateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(CouponsUpdate);
