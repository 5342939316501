import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Logo from '../../components/Logo/Logo';
import Footer from '../../components/Footer/Footer';
import {
  Form,
  Fieldset,
  Label,
  PasswordInput,
  OTPInput,
  Button,
  MessageBox,
  TextInput,
} from '../../components/FormElements';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import { authData } from '../../utils';
import OverlayLoader from '../../components/OverlayLoader/OverlayLoader';

const StyledAuthContainer = styled.section`
  width: 100%;
  height: 100%;
  background-color: #3c3c3c;
  padding: 0px;
  margin: 0px;
  position: fixed;
  top: 0;
  left: 0;
  background: #88bb22;
`;

const StyledAuthArea = styled.section`
  width: 95%;
  max-width: 500px;
  height: auto;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 0px;
  margin: 0px;
  box-shadow: 0px 0px 50px -10px #111b21;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  @media (max-width: 900px) {
    width: calc(100% - 40px);
    min-width: auto;
    height: auto;
    padding: 0px;
    margin: 0px;
    position: relative;
    top: 20px;
    left: 20px;
    transform: none;
  }
`;

const StyledHeader = styled.section`
  width: 100%;
  height: auto;
  margin: 0px;
  padding: 20px 0px 0px 0px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
`;

const StyledBody = styled.section`
  width: calc(100% - 60px);
  height: auto;
  margin: 0px;
  padding: 30px 30px 0px;
`;

const StyledAuthHeading = styled.h1`
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  margin: 0px 0px 30px 0px;
  padding: 0px;
`;

const StyleLink = styled(NavLink)`
  font-size: 14px;
  color: #0095ff;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledSpanAsButton = styled.span`
  font-size: 14px;
  color: #0095ff;
  position: absolute;
  top: 11.5px;
  right: 0;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledFooter = styled.section`
  width: calc(100% - 60px);
  height: auto;
  text-align: center;
  margin: 0px;
  padding: 30px;
  footer {
    padding: 0px;
  }
`;

const StyledPara = styled.p`
  font-size: 14px;
  text-align: center;
  line-height: 30px;
`;

const StyledSpan = styled.span`
  color: forestgreen;
`;

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [routeStage, setRouteStage] = useState('Login');
  const [loginType, setLoginType] = useState('Password');
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [requestToken, setRequestToken] = useState('');
  const [oneTimePassword, setOneTimePassword] = useState('');

  const resetForm = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
    setRouteStage('Login');
    setPassword('');
    setRequestToken('');
    setOneTimePassword('');
  };

  const onChangeRouteType = () => {
    resetForm();
    setLoginType((loginType) =>
      loginType === 'Password' ? 'OTP' : 'Password'
    );
  };

  const setAuthData = (data) => {
    const {
      token,
      data: { permissions },
    } = data;
    authData.set('isLoggedIn', true);
    authData.set('authId', emailAddress);
    authData.set('authToken', token);
    authData.set('accountsPermissions', permissions);
    authData.set('accountData', JSON.stringify(data.data));
    window.location.href = '/';
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    if (loginType === 'Password') {
      API.post('/auth/login', {
        loginType: loginType,
        emailAddress: emailAddress,
        password: password,
      })
        .then((response) => {
          const { status, message } = response.data;
          if (status === API_RESPONSE_TYPES.FAILURE) {
            setResponseStatus(status);
            setResponseMessage(message);
          } else {
            setAuthData(response.data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (loginType === 'OTP') {
      if (routeStage === 'Login') {
        API.post('/auth/login', {
          loginType: loginType,
          emailAddress: emailAddress,
        })
          .then((response) => {
            const { status, message, token } = response.data;
            setResponseStatus(status);
            setResponseMessage(message);
            setRequestToken(token);
            if (status === API_RESPONSE_TYPES.SUCCESS) {
              setRouteStage('OTP_VERIFICATION');
            }
          })
          .catch((error) => {
            setResponseStatus(API_RESPONSE_TYPES.FAILURE);
            setResponseMessage(error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else if (routeStage === 'OTP_VERIFICATION') {
        API.post('/auth/verify-otp', {
          requestType: 'Login',
          requestToken: requestToken,
          emailAddress: emailAddress,
          oneTimePassword: oneTimePassword
            ? oneTimePassword.join('')
            : oneTimePassword,
        })
          .then((response) => {
            const { status, message, token } = response.data;
            if (status === API_RESPONSE_TYPES.SUCCESS) {
              setAuthData(response.data);
            } else {
              setResponseStatus(status);
              setResponseMessage(message);
              setRequestToken(token);
            }
          })
          .catch((error) => {
            setResponseStatus(API_RESPONSE_TYPES.FAILURE);
            setResponseMessage(error.message);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const onClickResendOTP = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.post('/auth/resend-otp', {
      requestType: 'Login',
      requestToken: requestToken,
      emailAddress: emailAddress,
    })
      .then((response) => {
        const { status, message, token } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        setRequestToken(token);
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <StyledAuthArea>
        <StyledHeader>
          <Logo type='auth' />
        </StyledHeader>
        <StyledBody>
          <StyledAuthHeading>
            {routeStage === 'Login'
              ? `Login to your Account`
              : `Verify & Login`}
          </StyledAuthHeading>
          <Form action='#' method='POST' onSubmit={onSubmit}>
            {routeStage !== 'OTP_VERIFICATION' && (
              <>
                <Fieldset>
                  <Label required>Email Address</Label>
                  <TextInput
                    placeholder='Please enter your email address'
                    value={emailAddress}
                    onChange={setEmailAddress}
                    autoComplete='off'
                    disabled={isLoading}
                  />
                </Fieldset>
                {loginType === 'Password' && (
                  <Fieldset>
                    <Label required>Password</Label>
                    <StyleLink to='/forgot-password' title='Forgot Password ?'>
                      Forgot Password ?
                    </StyleLink>
                    <PasswordInput
                      placeholder='Please enter your password'
                      value={password}
                      onChange={setPassword}
                      autoComplete='off'
                      disabled={isLoading}
                    />
                  </Fieldset>
                )}
              </>
            )}
            {routeStage === 'OTP_VERIFICATION' && (
              <>
                <StyledPara>
                  Please enter your OTP sent to{' '}
                  <StyledSpan>{emailAddress}</StyledSpan>
                </StyledPara>
                <Fieldset>
                  <OTPInput
                    values={oneTimePassword}
                    onChange={setOneTimePassword}
                    length={6}
                    alignInputs='center'
                    disabled={isLoading}
                  />
                </Fieldset>
              </>
            )}
            {responseStatus && (
              <Fieldset>
                <MessageBox status={responseStatus} message={responseMessage} />
              </Fieldset>
            )}
            <Fieldset>
              <Button disabled={isLoading}>
                {routeStage === 'Login'
                  ? loginType === 'Password'
                    ? `Login`
                    : `Send OTP`
                  : `Verify & Proceed`}
              </Button>
              {routeStage === 'OTP_VERIFICATION' && (
                <StyledSpanAsButton onClick={onClickResendOTP}>
                  Resend OTP
                </StyledSpanAsButton>
              )}
              {routeStage === 'Login' && (
                <StyledSpanAsButton onClick={onChangeRouteType}>
                  Login with {loginType === 'Password' ? `OTP` : `Password`}
                </StyledSpanAsButton>
              )}
            </Fieldset>
          </Form>
        </StyledBody>
        <StyledFooter>
          <Footer />
        </StyledFooter>
        <OverlayLoader showLoader={isLoading} />
      </StyledAuthArea>
      <StyledAuthContainer />
    </>
  );
};

export default Login;
