import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Dropdown,
  Fieldset,
  FormContainer,
  Label,
  MessageBox,
  TextInput,
  UpdateButton,
  Form,
} from '../../components/FormElements';
import PageHeader from '../../components/PageHeader/PageHeader';
import { withAppHOC } from '../../hoc';
import { useParams } from 'react-router-dom';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import { formatCurrency } from '../../utils';
import { TableBuilder } from '../../components/TableElements';
import { Grid, GridItem } from '../../components/Grid';

const StyledOrderDetailsContainer = styled.section`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
`;

const StyledDetailsContainer = styled.section`
  flex: 1;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
  padding-bottom: 15px;
  h2 {
    font-size: 16px;
    color: #000000;
    background-color: #e5e5e5;
    margin: 0px;
    padding: 15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  label {
    margin: 0px;
    padding: 15px;
    color: #000000;
    font-weight: bold;
  }
  p {
    font-size: 14px;
    margin: 0px;
    padding: 0px 15px;
    color: #545454;
  }
  address {
    font-size: 14px;
    margin: 0px;
    padding: 15px;
    color: #545454;
  }
`;

const ProductsHeading = styled.h1`
  font-size: 24px;
  font-weight: normal;
  color: rgb(0, 0, 0);
  margin: 15px 0px;
  padding: 0px;
`;

const StyledOrderProductsContainer = styled.section``;

const StyledProductTitle = styled.section`
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  font-weight: bold;
`;

const StyledProductText = styled.p`
  font-size: 14px;
  margin: 0px;
  padding: 0px;
  line-height: 24px;
`;

const StyledActionsContainer = styled.section`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  margin: 20px 0px;
`;

const StyledActionContainer = styled.section`
  flex: 1;
  h3 {
    font-size: 16px;
    color: rgb(0, 0, 0);
    margin: 0px;
    padding: 0px 0px 20px 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

const Order = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [order, setOrder] = useState(null);
  const { orderId } = useParams();
  const [orderStatus, setOrderStatus] = useState('');
  const [shippingEntity, setShippingEntity] = useState('');
  const [trackingId, setTrackingId] = useState('');

  const readData = () => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/order-management/orders/${orderId}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          setOrder(data[0]);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateOrderStatus = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(`/order-management/update-order-status/${orderId}`, {
      orderStatus,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          readData();
          setTimeout(() => {
            setResponseStatus('');
            setResponseMessage('');
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateTrackingInformation = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.put(`/order-management/update-order-tracking-info/${orderId}`, {
      shippingEntity,
      trackingId,
    })
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          readData();
          setTimeout(() => {
            setResponseStatus('');
            setResponseMessage('');
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
  }, []);

  useEffect(() => {
    setOrderStatus(order?.orderStatus ? order?.orderStatus : '');
    setShippingEntity(order?.shippingEntity ? order?.shippingEntity : '');
    setTrackingId(order?.trackingId ? order?.trackingId : '');
  }, [order]);

  return (
    <>
      <PageHeader {...props} />
      <MessageBox status={responseStatus} message={responseMessage} />
      <StyledOrderDetailsContainer>
        <StyledDetailsContainer>
          <h2>Customer Details</h2>
          <Label>Name</Label>
          <p>
            {order?.firstName} {order?.lastName}
          </p>
          <Label>Mobile Number</Label>
          <p>{order?.mobileNumber}</p>
          {order?.emailAddress && (
            <>
              <Label>Email Address</Label>
              <p>{order?.emailAddress}</p>
            </>
          )}
        </StyledDetailsContainer>
        <StyledDetailsContainer>
          <h2>Shipping Details</h2>
          <Label>Address ( {order?.addressType} )</Label>
          <p>{order?.pointOfContactName}</p>
          <address>
            {order?.doorNumber}, {order?.streetLocality}, {order?.city},{' '}
            {order?.state}, {order?.country}, {order?.pincode}
          </address>
          <p>{order?.pointOfContactMobileNumber}</p>
          {order?.shippingEntity && order?.trackingId && (
            <>
              <Label>Tracking Details</Label>
              <p>
                {order?.shippingEntity} - {order?.trackingId}
              </p>
            </>
          )}
        </StyledDetailsContainer>
        <StyledDetailsContainer>
          <h2>Payment Details</h2>
          <Label>Order Status</Label>
          <p>{order?.orderStatus}</p>
          <Label>Payment Status</Label>
          <p>{order?.paymentStatus}</p>
          <Label>Payment Mode</Label>
          <p>{order?.paymentType}</p>
          <Label>Payment Verified</Label>
          <p>{order?.paymentVerificationStatus}</p>
          {order?.paymentId && (
            <>
              <Label>Payment ID</Label>
              <p>{order?.paymentId}</p>
            </>
          )}
        </StyledDetailsContainer>
      </StyledOrderDetailsContainer>
      <StyledActionsContainer>
        <StyledActionContainer>
          <FormContainer>
            <h3>Update Order Status</h3>
            <Form method={'POST'} onSubmit={updateOrderStatus}>
              <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
                <GridItem>
                  <Fieldset>
                    <Dropdown
                      value={orderStatus}
                      onChange={setOrderStatus}
                      options={[
                        { title: 'Pending', value: 'Pending' },
                        { title: 'Order Placed', value: 'Order Placed' },
                        { title: 'Confirmed', value: 'Confirmed' },
                        { title: 'Packed', value: 'Packed' },
                        { title: 'Shipped', value: 'Shipped' },
                        { title: 'Delayed', value: 'Delayed' },
                        { title: 'Delivered', value: 'Delivered' },
                        {
                          title: 'Replacement Requested',
                          value: 'Replacement Requested',
                        },
                        { title: 'Replaced', value: 'Replaced' },
                        {
                          title: 'Return Requested',
                          value: 'Return Requested',
                        },
                        { title: 'Returned', value: 'Returned' },
                        { title: 'Refunded', value: 'Refunded' },
                      ]}
                    />
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset>
                    <UpdateButton type='submit'>Update</UpdateButton>
                  </Fieldset>
                </GridItem>
              </Grid>
            </Form>
          </FormContainer>
        </StyledActionContainer>
        <StyledActionContainer>
          <FormContainer>
            <h3>Update Tracking Info</h3>
            <Form method={'POST'} onSubmit={updateTrackingInformation}>
              <Grid
                columns={
                  'calc(33.33% - 13.33px) calc(33.33% - 13.33px) calc(33.33% - 13.33px)'
                }
              >
                <GridItem>
                  <Fieldset>
                    <TextInput
                      value={shippingEntity}
                      onChange={setShippingEntity}
                      placeholder='Please enter shipping entity name'
                      disabled={isLoading}
                    />
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset>
                    <TextInput
                      value={trackingId}
                      onChange={setTrackingId}
                      placeholder='Please enter tracking id'
                      disabled={isLoading}
                    />
                  </Fieldset>
                </GridItem>
                <GridItem>
                  <Fieldset>
                    <UpdateButton type='submit'>Update</UpdateButton>
                  </Fieldset>
                </GridItem>
              </Grid>
            </Form>
          </FormContainer>
        </StyledActionContainer>
      </StyledActionsContainer>
      <ProductsHeading>Products</ProductsHeading>
      <StyledOrderProductsContainer>
        {order?.products && (
          <TableBuilder
            isLoading={order?.products.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: 'Product Details',
                dataSelector: 'productName',
                dataType: 'string',
                CellRenderer: (value, item) => (
                  <>
                    <StyledProductTitle>{value}</StyledProductTitle>
                    <StyledProductText>
                      <b>Category : </b>
                      {item.category}
                    </StyledProductText>
                    <StyledProductText>
                      <b>Subcategory : </b>
                      {item.subCategory}
                    </StyledProductText>
                    <StyledProductText>
                      <b>Price : </b>
                      {formatCurrency(
                        item.discountedPrice ? item.discountedPrice : item.price
                      )}
                    </StyledProductText>
                  </>
                ),
              },
              {
                title: 'Quantity',
                dataSelector: 'quantity',
                dataType: 'string',
                align: 'center',
              },
              {
                title: 'Total',
                dataSelector: 'total',
                dataType: 'string',
                align: 'center',
                CellRenderer: (value) => formatCurrency(value),
              },
            ]}
            tableData={order?.products}
            tableFooter={[
              {
                rows: [
                  {
                    value: '',
                  },
                  {
                    value: 'Subtotal',
                    align: 'center',
                  },
                  {
                    value: formatCurrency(order?.subtotal),
                    align: 'center',
                  },
                ],
              },
              {
                rows: [
                  {
                    value: '',
                  },
                  {
                    value: 'Discount',
                    align: 'center',
                  },
                  {
                    value: formatCurrency(order?.discount),
                    align: 'center',
                  },
                ],
              },
              {
                rows: [
                  {
                    value: '',
                  },
                  {
                    value: 'Shipping',
                    align: 'center',
                  },
                  {
                    value: formatCurrency(order?.shippingCharge),
                    align: 'center',
                  },
                ],
              },
              {
                rows: [
                  {
                    value: '',
                  },
                  {
                    value: 'Total',
                    align: 'center',
                  },
                  {
                    value: formatCurrency(order?.total),
                    align: 'center',
                  },
                ],
              },
            ]}
          />
        )}
      </StyledOrderProductsContainer>
    </>
  );
};

export default withAppHOC(Order);
