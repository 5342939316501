import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import { withAppHOC } from '../../hoc';
import PageHeading from '../../components/PageHeader/PageHeader';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import NoData from '../../components/NoData/NoData';
import {
  MessageBox,
  Form,
  Fieldset,
  TextInput,
  Button,
  ResetButton,
  Label,
} from '../../components/FormElements';
import { TableBuilder } from '../../components/TableElements';
import Pagination from '../../components/Pagination/Pagination';
import FiltersButton from '../../components/FiltersButton/FiltersButton';
import { StyledLink, StyledFiltersContainer } from '../../components/Styled';
import API from '../../api';
import { API_RESPONSE_TYPES } from '../../constants';
import {
  getSearchParams,
  shouldRedirect,
  redirectToPageOne,
} from '../../utils';

const OrderManagement = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isFiltering, setIsFiltering] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [paginationData, setPaginationData] = useState({
    pageNumber: getSearchParams('pageNumber') || 1,
    recordsPerPage: getSearchParams('recordsPerPage') || 30,
  });
  const [orders, setOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const readData = useCallback(
    (searchQuery = '') => {
      setIsLoading(true);
      setResponseStatus('');
      setResponseMessage('');
      setOrders([]);
      API.get(
        `/order-management/orders/?pageNumber=${paginationData.pageNumber}&recordsPerPage=${paginationData.recordsPerPage}&searchQuery=${searchQuery}`
      )
        .then((response) => {
          const { status, message, data, totalRecords } = response.data;
          if (shouldRedirect(data?.length, paginationData?.pageNumber)) {
            redirectToPageOne();
          } else {
            if (status === API_RESPONSE_TYPES.FAILURE) {
              setResponseStatus(status);
              setResponseMessage(message);
            } else {
              setTotalRecords(totalRecords);
            }
            setOrders(data);
          }
        })
        .catch((error) => {
          setResponseStatus(API_RESPONSE_TYPES.FAILURE);
          setResponseMessage(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [paginationData]
  );

  const reloadData = () => {
    readData(searchQuery);
  };

  const filterData = (e) => {
    e.preventDefault();
    setIsFiltering(true);
  };

  const resetForm = () => {
    setSearchQuery('');
    readData();
  };

  const onClickShowFilters = () => {
    setShowFilters((showFilters) => !showFilters);
  };

  useEffect(() => {
    if (isFiltering) {
      setPaginationData((paginationData) => ({
        pageNumber: 1,
        recordsPerPage: paginationData.recordsPerPage,
      }));
      setIsFiltering((isFiltering) => !isFiltering);
    }
  }, [isFiltering]);

  useEffect(() => {
    if (!isFiltering) {
      readData(searchQuery);
    }
  }, [paginationData, readData]);

  return (
    <>
      <PageHeading {...props} canReload={true} reloadData={reloadData} />
      <FiltersButton onClick={onClickShowFilters} value={showFilters} />
      <StyledFiltersContainer className={showFilters ? 'show' : ''}>
        <Form method='POST' action='#' onSubmit={filterData}>
          <Fieldset>
            <Label>Search</Label>
            <TextInput
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder='Order ID, First Name, Last Name, Mobile, Email'
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Button disabled={isLoading} type='submit'>
              Filter
            </Button>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Clear
            </ResetButton>
          </Fieldset>
        </Form>
      </StyledFiltersContainer>
      <LoadingSpinner
        isLoading={responseStatus === '' && orders.length === 0 && isLoading}
      />
      <NoData
        status={
          responseStatus !== API_RESPONSE_TYPES.FAILURE &&
          !isLoading &&
          orders.length === 0
        }
        message={`No orders found`}
      />
      {responseStatus === API_RESPONSE_TYPES.FAILURE && (
        <MessageBox status={responseStatus} message={responseMessage} />
      )}
      {orders.length > 0 && (
        <>
          <TableBuilder
            isLoading={orders.length !== 0 && isLoading}
            tableHeadings={[
              {
                title: 'Order ID',
                dataSelector: 'orderId',
                dataType: 'string',
                CellRenderer: (value) => (
                  <StyledLink to={`order/${value}`} title={'Order Details'}>
                    {value}
                  </StyledLink>
                ),
              },
              {
                title: 'Customer Name',
                dataSelector: 'firstName',
                dataType: 'string',
                CellRenderer: (value, item) =>
                  value ? `${value} ${item.lastName}` : 'N/A',
              },
              {
                title: 'Mobile Number',
                dataSelector: 'mobileNumber',
                dataType: 'string',
              },
              {
                title: 'Email Address',
                dataSelector: 'emailAddress',
                dataType: 'string',
              },
              {
                title: 'Order Status',
                dataSelector: 'orderStatus',
                dataType: 'string',
              },
              {
                title: 'Created On',
                dataSelector: 'createdOn',
                dataType: 'date',
                CellRenderer: (value) =>
                  moment(value).format('YYYY-MM-DD hh:mm:ss A'),
              },
            ]}
            tableData={orders}
          />
          <Pagination
            totalRecords={totalRecords}
            paginationData={paginationData}
            setPaginationData={setPaginationData}
          />
        </>
      )}
    </>
  );
};

export default withAppHOC(OrderManagement);
