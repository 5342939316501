import React, { useState, useEffect } from 'react';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  FileInput,
  Dropdown,
  MessageBox,
  CreateButton,
  ResetButton,
  NumberInput,
} from '../../../components/FormElements';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getBase64, getReturnUrl } from '../../../utils';
import { Grid, GridItem } from '../../../components/Grid';

const ProductsCreate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [categories, setCategories] = useState('');
  const [subcategories, setSubcategories] = useState('');
  const [productName, setProductName] = useState('');
  const [description, setDescription] = useState('');
  const [categoriesId, setCategoriesId] = useState('');
  const [subcategoriesId, setSubcategoriesId] = useState('');
  const [tags, setTags] = useState('');
  const [price, setPrice] = useState('');
  const [specialPrice, setSpecialPrice] = useState('');
  const [stock, setStock] = useState('');
  const [maxAllowed, setMaxAllowed] = useState('');
  const [productStatus, setProductStatus] = useState('');
  const [productImages, setProductImages] = useState([]);
  const [productImagesBase64, setProductImagesBase64] = useState([]);

  const readCategories = () => {
    API.get(`/content-management/categories`).then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setCategories(data);
      }
    });
  };

  const readSubcategories = () => {
    API.get(`/content-management/subcategories`).then((response) => {
      const { status, data } = response.data;
      if (status === API_RESPONSE_TYPES.SUCCESS) {
        setSubcategories(data);
      }
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      productName,
      description,
      categoriesId,
      subcategoriesId,
      tags,
      price,
      specialPrice,
      stock,
      maxAllowed,
      productStatus,
      productImages: productImagesBase64,
    };
    API.post('/content-management/products', formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          resetForm();
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.CREATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    setProductName('');
    setDescription('');
    setCategoriesId('');
    setSubcategoriesId('');
    setTags('');
    setPrice('');
    setSpecialPrice('');
    setStock('');
    setMaxAllowed('');
    setProductStatus('');
    setProductImages([]);
  };

  useEffect(() => {
    readCategories();
    readSubcategories();
  }, []);

  const categoriesOptions = [
    {
      title: '-- SELECT CATEGORY --',
      value: '',
    },
  ];
  categories &&
    categories.length > 0 &&
    categories.forEach(({ id, categoryName }) =>
      categoriesOptions.push({
        title: categoryName,
        value: id,
      })
    );

  const subcategoriesOptions = [
    {
      title: '-- SELECT SUBCATEGORY --',
      value: '',
    },
  ];
  subcategories &&
    subcategories.length > 0 &&
    subcategories
      .filter((item) =>
        categoriesId ? item.categoriesId === categoriesId : true
      )
      .forEach(({ id, subCategoryName }) =>
        subcategoriesOptions.push({
          title: subCategoryName,
          value: id,
        })
      );

  useEffect(() => {
    if (productImages?.length > 0) {
      const productImagesBase64 = [];
      const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      };
      async function processImages() {
        for (let i = 0; i < productImages.length; i++) {
          const file = productImages[i];
          try {
            const base64Data = await convertToBase64(file);
            productImagesBase64.push(base64Data);
          } catch (error) {
            console.error('Error converting image to base64:', error);
          }
        }
        setProductImagesBase64(productImagesBase64);
      }
      processImages();
    }
  }, [productImages]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form
          method='POST'
          action='#'
          onSubmit={onSubmit}
          encType='multipart/form-data'
        >
          <Fieldset>
            <Label required>Product Name</Label>
            <TextInput
              value={productName}
              onChange={setProductName}
              placeholder='Please enter product name'
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Description</Label>
            <TextInput
              value={description}
              onChange={setDescription}
              placeholder='Please enter description'
              disabled={isLoading}
            />
          </Fieldset>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Category</Label>
                <Dropdown
                  placeholder='Please select category'
                  value={categoriesId}
                  onChange={setCategoriesId}
                  options={categoriesOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Subcategory</Label>
                <Dropdown
                  placeholder='Please select subcategory'
                  value={subcategoriesId}
                  onChange={setSubcategoriesId}
                  options={subcategoriesOptions}
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label>Tags</Label>
            <TextInput
              value={tags}
              onChange={setTags}
              placeholder='Please enter tags'
              disabled={isLoading}
            />
          </Fieldset>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Price</Label>
                <NumberInput
                  value={price}
                  onChange={setPrice}
                  placeholder='Please enter price'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label>Special Price</Label>
                <NumberInput
                  value={specialPrice}
                  onChange={setSpecialPrice}
                  placeholder='Please enter special price'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Grid columns={'calc(50% - 10px) calc(50% - 10px)'}>
            <GridItem>
              <Fieldset>
                <Label required>Stock</Label>
                <NumberInput
                  value={stock}
                  onChange={setStock}
                  placeholder='Please enter stock'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
            <GridItem>
              <Fieldset>
                <Label required>Max Allowed</Label>
                <NumberInput
                  value={maxAllowed}
                  onChange={setMaxAllowed}
                  placeholder='Please enter maximum quantity allowed per order'
                  disabled={isLoading}
                />
              </Fieldset>
            </GridItem>
          </Grid>
          <Fieldset>
            <Label required>Product Status</Label>
            <Dropdown
              placeholder='Please select product status'
              value={productStatus}
              onChange={setProductStatus}
              options={[
                {
                  title: '-- SELECT PRODUCT STATUS --',
                  value: '',
                },
                {
                  title: 'Active',
                  value: 'Active',
                },
                {
                  title: 'Inactive',
                  value: 'Inactive',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Product Images</Label>
            <FileInput
              files={productImages}
              onChange={setProductImages}
              accept='image/*'
              disabled={isLoading}
              multiple
              preview
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <CreateButton disabled={isLoading} type='submit'>
              Create
            </CreateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
      </FormContainer>
    </>
  );
};

export default withAppHOC(ProductsCreate);
