import {
  faHome,
  faUsers,
  faDatabase,
  faShippingFast,
  faMarker,
} from '@fortawesome/free-solid-svg-icons';
import { MODULES, PERMISSION_TYPES, ROUTE_TYPES } from './index';
import Login from '../containers/Auth/Login';
import ForgotPassword from '../containers/Auth/ForgotPassword';
import Profile from '../containers/Auth/Profile';
import ChangePassword from '../containers/Auth/ChangePassword';
import Dashboard from '../containers/Dashboard/Dashboard';
import RolesAndPermissionsList from '../containers/AccountsManagement/RolesAndPermissions/RolesAndPermissionsList';
import RolesAndPermissionsCreate from '../containers/AccountsManagement/RolesAndPermissions/RolesAndPermissionsCreate';
import RolesAndPermissionsUpdate from '../containers/AccountsManagement/RolesAndPermissions/RolesAndPermissionsUpdate';
import AccountsList from '../containers/AccountsManagement/Accounts/AccountsList';
import AccountsCreate from '../containers/AccountsManagement/Accounts/AccountsCreate';
import AccountsUpdate from '../containers/AccountsManagement/Accounts/AccountsUpdate';
import UsersList from '../containers/AccountsManagement/Users/UsersList';
import UsersCreate from '../containers/AccountsManagement/Users/UsersCreate';
import UsersUpdate from '../containers/AccountsManagement/Users/UsersUpdate';
import UsersTrack from '../containers/AccountsManagement/Users/UsersTrack';
import BannersList from '../containers/ContentManagement/Banners/BannersList';
import BannersCreate from '../containers/ContentManagement/Banners/BannersCreate';
import BannersUpdate from '../containers/ContentManagement/Banners/BannersUpdate';
import CategoriesList from '../containers/ContentManagement/Categories/CategoriesList';
import CategoriesCreate from '../containers/ContentManagement/Categories/CategoriesCreate';
import CategoriesUpdate from '../containers/ContentManagement/Categories/CategoriesUpdate';
import SubcategoriesList from '../containers/ContentManagement/Subcategories/SubcategoriesList';
import SubcategoriesCreate from '../containers/ContentManagement/Subcategories/SubcategoriesCreate';
import SubcategoriesUpdate from '../containers/ContentManagement/Subcategories/SubcategoriesUpdate';
import ProductsList from '../containers/ContentManagement/Products/ProductsList';
import ProductsCreate from '../containers/ContentManagement/Products/ProductsCreate';
import ProductsUpdate from '../containers/ContentManagement/Products/ProductsUpdate';
import CouponsList from '../containers/ContentManagement/Coupons/CouponsList';
import CouponsCreate from '../containers/ContentManagement/Coupons/CouponsCreate';
import CouponsUpdate from '../containers/ContentManagement/Coupons/CouponsUpdate';
import RatingsAndReviewsList from '../containers/ContentManagement/RatingsAndReviews/RatingsAndReviewsList';
import OrderManagement from '../containers/OrderManagement/OrderManagement';
import Order from '../containers/OrderManagement/Order';
import ProductsInCart from '../containers/Marketing/ProductsInCart';
import ProductsInWishlist from '../containers/Marketing/ProductsInWishlist';

const {
  DASHBOARD,
  // Accounts Management
  ACCOUNTS_MANAGEMENT,
  ROLES_AND_PERMISSIONS,
  ACCOUNTS,
  USERS,
  // Content Management
  CONTENT_MANAGEMENT,
  BANNERS,
  CATEGORIES,
  SUBCATEGORIES,
  PRODUCTS,
  COUPONS,
  RATINGS_AND_REVIEWS,
  // Order Management
  ORDER_MANAGEMENT,
  // Marketing
  MARKETING,
  PRODUCTS_IN_CART,
  PRODUCTS_IN_WISHLIST,
} = MODULES;
const { CREATE, READ, UPDATE, TRACK } = PERMISSION_TYPES;
const { ALLOW_IF_LOGGEDIN, ALLOW_IF_NOT_LOGGEDIN } = ROUTE_TYPES;

const ROUTES = [
  {
    path: '/',
    title: 'Home',
    icon: faHome,
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: DASHBOARD,
    permissionRequired: READ,
    component: Dashboard,
    hideHeading: true,
    hideBreadcrumbs: true,
    exact: true,
  },
  {
    path: '/login',
    title: 'Login',
    routeType: ALLOW_IF_NOT_LOGGEDIN,
    component: Login,
    hideInNavigation: true,
  },
  {
    path: '/forgot-password',
    title: 'Forgot Password',
    routeType: ALLOW_IF_NOT_LOGGEDIN,
    component: ForgotPassword,
    hideInNavigation: true,
  },
  {
    path: '/profile',
    title: 'My Profile',
    routeType: ALLOW_IF_LOGGEDIN,
    component: Profile,
    hideInNavigation: true,
    showInHeader: true,
  },
  {
    path: '/change-password',
    title: 'Change Password',
    routeType: ALLOW_IF_LOGGEDIN,
    component: ChangePassword,
    hideInNavigation: true,
    showInHeader: true,
  },
  {
    path: '/accounts-management',
    title: 'Accounts Management',
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: ACCOUNTS_MANAGEMENT,
    permissionRequired: READ,
    icon: faUsers,
    isDummy: true,
    subRoutes: [
      {
        path: '/accounts-management/roles-and-permissions',
        title: 'Roles & Permissions',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ROLES_AND_PERMISSIONS,
        permissionRequired: READ,
        component: RolesAndPermissionsList,
        masterModule: ACCOUNTS_MANAGEMENT,
        subRoutes: [
          {
            path: '/accounts-management/roles-and-permissions/create',
            title: 'Create Roles & Permissions',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ROLES_AND_PERMISSIONS,
            permissionRequired: CREATE,
            component: RolesAndPermissionsCreate,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounts-management/roles-and-permissions/update/:id',
            title: 'Update Roles & Permissions',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ROLES_AND_PERMISSIONS,
            permissionRequired: UPDATE,
            component: RolesAndPermissionsUpdate,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/accounts-management/accounts',
        title: 'Accounts',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ACCOUNTS,
        permissionRequired: READ,
        component: AccountsList,
        masterModule: ACCOUNTS_MANAGEMENT,
        subRoutes: [
          {
            path: '/accounts-management/accounts/create',
            title: 'Create Account',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ACCOUNTS,
            permissionRequired: CREATE,
            component: AccountsCreate,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounts-management/accounts/update/:id',
            title: 'Update Account',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: ACCOUNTS,
            permissionRequired: UPDATE,
            component: AccountsUpdate,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/accounts-management/users',
        title: 'Users',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: USERS,
        permissionRequired: READ,
        component: UsersList,
        masterModule: ACCOUNTS_MANAGEMENT,
        subRoutes: [
          {
            path: '/accounts-management/users/create',
            title: 'Create Customer',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: USERS,
            permissionRequired: CREATE,
            component: UsersCreate,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounts-management/users/update/:id',
            title: 'Update Customer',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: USERS,
            permissionRequired: UPDATE,
            component: UsersUpdate,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/accounts-management/users/track/:id',
            title: 'Track Customer',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: USERS,
            permissionRequired: TRACK,
            component: UsersTrack,
            masterModule: ACCOUNTS_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
    ],
  },
  {
    path: '/content-management',
    title: 'Content Management',
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: CONTENT_MANAGEMENT,
    permissionRequired: READ,
    icon: faDatabase,
    isDummy: true,
    subRoutes: [
      {
        path: '/content-management/banners',
        title: 'Banners',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: BANNERS,
        permissionRequired: READ,
        component: BannersList,
        masterModule: CONTENT_MANAGEMENT,
        subRoutes: [
          {
            path: '/content-management/banners/create',
            title: 'Create Banner',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: BANNERS,
            permissionRequired: CREATE,
            component: BannersCreate,
            masterModule: CONTENT_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/content-management/banners/update/:id',
            title: 'Update Banner',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: BANNERS,
            permissionRequired: UPDATE,
            component: BannersUpdate,
            masterModule: CONTENT_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/content-management/categories',
        title: 'Categories',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: CATEGORIES,
        permissionRequired: READ,
        component: CategoriesList,
        masterModule: CONTENT_MANAGEMENT,
        subRoutes: [
          {
            path: '/content-management/categories/create',
            title: 'Create Category',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: CATEGORIES,
            permissionRequired: CREATE,
            component: CategoriesCreate,
            masterModule: CONTENT_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/content-management/categories/update/:id',
            title: 'Update Category',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: CATEGORIES,
            permissionRequired: UPDATE,
            component: CategoriesUpdate,
            masterModule: CONTENT_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/content-management/subcategories',
        title: 'Subcategories',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: SUBCATEGORIES,
        permissionRequired: READ,
        component: SubcategoriesList,
        masterModule: CONTENT_MANAGEMENT,
        subRoutes: [
          {
            path: '/content-management/subcategories/create',
            title: 'Create Subcategory',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: SUBCATEGORIES,
            permissionRequired: CREATE,
            component: SubcategoriesCreate,
            masterModule: CONTENT_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/content-management/subcategories/update/:id',
            title: 'Update Subcategory',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: SUBCATEGORIES,
            permissionRequired: UPDATE,
            component: SubcategoriesUpdate,
            masterModule: CONTENT_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/content-management/products',
        title: 'Products',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: PRODUCTS,
        permissionRequired: READ,
        component: ProductsList,
        masterModule: CONTENT_MANAGEMENT,
        subRoutes: [
          {
            path: '/content-management/products/create',
            title: 'Create Product',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: PRODUCTS,
            permissionRequired: CREATE,
            component: ProductsCreate,
            masterModule: CONTENT_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/content-management/products/update/:id',
            title: 'Update Product',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: PRODUCTS,
            permissionRequired: UPDATE,
            component: ProductsUpdate,
            masterModule: CONTENT_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/content-management/coupons',
        title: 'Coupons',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: COUPONS,
        permissionRequired: READ,
        component: CouponsList,
        masterModule: CONTENT_MANAGEMENT,
        subRoutes: [
          {
            path: '/content-management/coupons/create',
            title: 'Create Coupon',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: COUPONS,
            permissionRequired: CREATE,
            component: CouponsCreate,
            masterModule: CONTENT_MANAGEMENT,
            hideInNavigation: true,
          },
          {
            path: '/content-management/coupons/update/:id',
            title: 'Update Coupon',
            routeType: ALLOW_IF_LOGGEDIN,
            moduleName: COUPONS,
            permissionRequired: UPDATE,
            component: CouponsUpdate,
            masterModule: CONTENT_MANAGEMENT,
            hideInNavigation: true,
          },
        ],
      },
      {
        path: '/content-management/ratings-and-reviews',
        title: 'Ratings & Reviews',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: RATINGS_AND_REVIEWS,
        permissionRequired: READ,
        component: RatingsAndReviewsList,
        masterModule: CONTENT_MANAGEMENT,
      },
    ],
  },
  {
    path: '/order-management',
    title: 'Order Management',
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: ORDER_MANAGEMENT,
    permissionRequired: READ,
    component: OrderManagement,
    icon: faShippingFast,
    subRoutes: [
      {
        path: '/order-management/order/:orderId',
        title: 'Order Details',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: ORDER_MANAGEMENT,
        permissionRequired: READ,
        component: Order,
        hideInNavigation: true,
      },
    ],
  },
  {
    path: '/marketing',
    title: 'Marketing',
    routeType: ALLOW_IF_LOGGEDIN,
    moduleName: MARKETING,
    permissionRequired: READ,
    icon: faMarker,
    isDummy: true,
    subRoutes: [
      {
        path: '/marketing/products-in-cart',
        title: 'Products In Cart',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: PRODUCTS_IN_CART,
        permissionRequired: READ,
        component: ProductsInCart,
        masterModule: MARKETING,
      },
      {
        path: '/marketing/products-in-wishlist',
        title: 'Products In Wishlist',
        routeType: ALLOW_IF_LOGGEDIN,
        moduleName: PRODUCTS_IN_WISHLIST,
        permissionRequired: READ,
        component: ProductsInWishlist,
        masterModule: MARKETING,
      },
    ],
  },
];

export { ROUTES };
