import { MODULES, PERMISSION_TYPES } from './index';

const {
  DASHBOARD,
  // Accounts Management
  ACCOUNTS_MANAGEMENT,
  ROLES_AND_PERMISSIONS,
  ACCOUNTS,
  USERS,
  // Content Management
  CONTENT_MANAGEMENT,
  BANNERS,
  CATEGORIES,
  SUBCATEGORIES,
  PRODUCTS,
  COUPONS,
  RATINGS_AND_REVIEWS,
  // Order Management
  ORDER_MANAGEMENT,
  // Marketing
  MARKETING,
  PRODUCTS_IN_CART,
  PRODUCTS_IN_WISHLIST,
} = MODULES;

const {
  CREATE,
  READ,
  UPDATE,
  DELETE,
  TRACK,
  UPDATE_ORDER_TRACKING_INFO,
  UPDATE_ORDER_STATUS,
} = PERMISSION_TYPES;

const modulesMapper = [
  {
    moduleName: DASHBOARD,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  // Accounts Management
  {
    moduleName: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: ROLES_AND_PERMISSIONS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
      {
        permission: DELETE,
      },
    ],
  },
  {
    moduleName: ACCOUNTS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
      {
        permission: DELETE,
      },
    ],
  },
  {
    moduleName: USERS,
    masterModule: ACCOUNTS_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
      {
        permission: DELETE,
      },
      {
        permission: TRACK,
      },
    ],
  },
  // Content Management
  {
    moduleName: CONTENT_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: BANNERS,
    masterModule: CONTENT_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
      {
        permission: DELETE,
      },
    ],
  },
  {
    moduleName: CATEGORIES,
    masterModule: CONTENT_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
      {
        permission: DELETE,
      },
    ],
  },
  {
    moduleName: SUBCATEGORIES,
    masterModule: CONTENT_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
      {
        permission: DELETE,
      },
    ],
  },
  {
    moduleName: PRODUCTS,
    masterModule: CONTENT_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
      {
        permission: DELETE,
      },
    ],
  },
  {
    moduleName: COUPONS,
    masterModule: CONTENT_MANAGEMENT,
    permissions: [
      {
        permission: CREATE,
      },
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
      {
        permission: DELETE,
      },
    ],
  },
  {
    moduleName: RATINGS_AND_REVIEWS,
    masterModule: CONTENT_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE,
      },
    ],
  },
  // Order Management
  {
    moduleName: ORDER_MANAGEMENT,
    permissions: [
      {
        permission: READ,
      },
      {
        permission: UPDATE_ORDER_TRACKING_INFO,
      },
      {
        permission: UPDATE_ORDER_STATUS,
      },
    ],
  },
  // Marketing
  {
    moduleName: MARKETING,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: PRODUCTS_IN_CART,
    masterModule: MARKETING,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
  {
    moduleName: PRODUCTS_IN_WISHLIST,
    masterModule: MARKETING,
    permissions: [
      {
        permission: READ,
      },
    ],
  },
];

export default modulesMapper;
