import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { withAppHOC } from '../../../hoc';
import PageHeading from '../../../components/PageHeader/PageHeader';
import OverlayLoader from '../../../components/OverlayLoader/OverlayLoader';
import {
  FormContainer,
  Form,
  Fieldset,
  Label,
  TextInput,
  FileInput,
  Dropdown,
  MessageBox,
  UpdateButton,
  ResetButton,
} from '../../../components/FormElements';
import Modal from '../../../components/Modal';
import { Image } from '../../../components/Image';
import API from '../../../api';
import { API_RESPONSE_TYPES, PERMISSION_TYPES } from '../../../constants';
import { getReturnUrl, getBase64 } from '../../../utils';

const StyledViewAttachmentButton = styled.span`
  font-size: 14px;
  width: calc(100% - 22px);
  height: auto;
  border: 1px solid #d0eeff;
  display: block;
  margin: 20px 0px 0px;
  background-color: #edf8ff;
  padding: 15px 10px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s;
  position: relative;
  &:hover {
    background-color: #d0eeff;
  }
`;

const BannersUpdate = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [responseStatus, setResponseStatus] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [showExistingImage, setShowExistingImage] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [bannerName, setBannerName] = useState('');
  const [bannerImage, setBannerImage] = useState('');
  const [bannerBase64, setBannerBase64] = useState('');
  const [bannerStatus, setBannerStatus] = useState('');
  const [originalFormData, setOriginalFormData] = useState({});
  const { id } = useParams();

  const resetResponseData = () => {
    setIsLoading(false);
    setResponseStatus('');
    setResponseMessage('');
  };

  const resetForm = () => {
    const { bannerName, bannerStatus } = originalFormData;
    setBannerName(bannerName);
    setBannerStatus(bannerStatus);
  };

  const readData = useCallback(() => {
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    API.get(`/content-management/banners/${id}`)
      .then((response) => {
        const { status, message, data } = response.data;
        if (status === API_RESPONSE_TYPES.FAILURE) {
          setResponseStatus(status);
          setResponseMessage(message);
        } else {
          const { bannerName, bannerImage, bannerStatus } = data[0];
          setBannerName(bannerName);
          setBannerImage([]);
          setBannerBase64('');
          setBannerStatus(bannerStatus);
          setOriginalFormData({
            bannerName,
            bannerImage,
            bannerStatus,
          });
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResponseStatus('');
    setResponseMessage('');
    const formData = {
      bannerName,
      bannerStatus,
      bannerImage: bannerBase64 ? bannerBase64 : originalFormData.bannerImage,
    };
    API.put(`/content-management/banners/${id}`, formData)
      .then((response) => {
        const { status, message } = response.data;
        setResponseStatus(status);
        setResponseMessage(message);
        if (status === API_RESPONSE_TYPES.SUCCESS) {
          setOriginalFormData(formData);
          setTimeout(() => {
            resetResponseData();
            window.location.href = getReturnUrl(PERMISSION_TYPES.UPDATE);
          }, 3000);
        }
      })
      .catch((error) => {
        setResponseStatus(API_RESPONSE_TYPES.FAILURE);
        setResponseMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    readData();
  }, [readData]);

  useEffect(() => {
    if (bannerImage?.length > 0) {
      getBase64(bannerImage[0], setBannerBase64);
    }
  }, [bannerImage]);

  return (
    <>
      <PageHeading {...props} />
      <FormContainer>
        <Form
          method='POST'
          action='#'
          onSubmit={onSubmit}
          enctype='multipart/form-data'
        >
          <Fieldset>
            <Label required>Banner Name</Label>
            <TextInput
              value={bannerName}
              onChange={setBannerName}
              placeholder='Please enter banner name'
              maxLength={100}
              disabled={isLoading}
            />
          </Fieldset>
          <Fieldset>
            <Label>Banner Image</Label>
            <FileInput
              files={bannerImage}
              onChange={setBannerImage}
              accept='image/*'
              disabled={isLoading}
            />
            {originalFormData?.bannerImage && (
              <StyledViewAttachmentButton
                onClick={() => {
                  setShowExistingImage(true);
                  setModalImage(originalFormData.bannerImage);
                }}
              >
                Click here to view existing file
              </StyledViewAttachmentButton>
            )}
          </Fieldset>
          <Fieldset>
            <Label required>Banner Status</Label>
            <Dropdown
              placeholder='Please select banner status'
              value={bannerStatus}
              onChange={setBannerStatus}
              options={[
                {
                  title: '-- SELECT BANNER STATUS --',
                  value: '',
                },
                {
                  title: 'Active',
                  value: 'Active',
                },
                {
                  title: 'Inactive',
                  value: 'Inactive',
                },
              ]}
              disabled={isLoading}
            />
          </Fieldset>
          {responseStatus && (
            <Fieldset>
              <MessageBox status={responseStatus} message={responseMessage} />
            </Fieldset>
          )}
          <Fieldset>
            <UpdateButton disabled={isLoading} type='submit'>
              Update
            </UpdateButton>
            <ResetButton disabled={isLoading} type='button' onClick={resetForm}>
              Reset
            </ResetButton>
          </Fieldset>
        </Form>
        <OverlayLoader showLoader={isLoading} />
        <Modal
          modalStatus={showExistingImage}
          setModalStatus={setShowExistingImage}
          hideCloseButton
        >
          <Image width={150} source={modalImage} alt={'Existing File'} />
        </Modal>
      </FormContainer>
    </>
  );
};

export default withAppHOC(BannersUpdate);
